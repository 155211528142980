.about + .mini-gallery {
  padding-top: 18px;
  padding-bottom: 60px;

  @media (min-width: 768px) {
    padding-top: 43px;
    padding-bottom: 99px;
  }
}

.mini-gallery {
  .section-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.38;

    @media (min-width: 768px) {
      font-size: 36px;
      margin-top: 21px;
      margin-bottom: 34px;
    }
  }

  .about__text {
    @media (min-width: 768px) {
      max-width: 405px;
      margin-left: 0;
    }

    p {
      letter-spacing: 0.5px;
      font-weight: 500;

      @media (min-width: 768px) {
        margin-bottom: 22px;
      }

      &:first-child {
        font-weight: 600;
      }
    }
  }

  .about__button {
    margin-top: 19px;

    @media (min-width: 768px) {
      margin-top: 39px;
    }

    a {
      &:first-child {
        max-width: 175px;
      }
    }
  }
}

.mini-gallery__col {
  &:first-child {
    margin-bottom: 39px;
  }

  &:last-child {

  }
}

.mini-gallery__images-row {
  margin: 0 -5px;

  [class^="col"] {
    padding: 0 5px;
    margin-bottom: 10px;
  }

}

.mini-gallery__item {
  border-radius: $border-radius-min;
  overflow: hidden;
  height: 200px;

  @media (min-width: 768px) {
    height: 250px;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.about--steps {
  .section-title {
    margin-bottom: 54px;
  }
}