.main-map {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;

  @media (min-width: 768px) {
    padding-top: 42px;
  }
  
  .container {
    @media (min-width: 992px) {
      position: relative;
    }
  }

  .section-title {
    margin-bottom: 39px;
    font-size: 24px;
    font-weight: 800;

    @media (min-width: 768px) {
      margin-bottom: 57px;
      font-size: 30px;
    }

    @media (min-width: 992px) {

    }

    &::before {
      content: none;
    }
  }
}

.main-map__wrap {
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 62.5vw;

  @media (min-width: 768px) {
    height: 411px;
  }

  #map {
    width: 100%;
    height: 100%;
  }

  .map-class {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin-top: 0;
    padding-top: 0;

    @media (min-width: 768px) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.main-map__plate {
  border-radius: $border-radius-min;
  overflow: hidden;
}

.main-map__info-wrapper {
  position: relative;
  pointer-events: none;
}

.main-map__contacts {
  background-color: #fff;
  padding: 39px 20px 3px 30px;
  pointer-events: auto;

  @media (min-width: 768px) {
    padding: 56px 20px 25px 49px;
  }

  @media (min-width: 992px) {
    padding: 85px 143px 27px 99px;
  }
  
  .row {
    .main-map__contacts-position-col {
      @media (min-width: 768px) {
        position: relative;
        left: -86px;
      }

      @media (min-width: 992px) {
        position: static;
      }
    }
    
    .main-map__contacts-col {
      @media (min-width: 1200px) {
        display: flex;
        
        .main-map__contacts-item {
          @media (min-width: 1200px) {
            margin-left: auto;
            margin-right: 0;
          }
        }
      }
    }
  }
}

.main-map__contacts-item {
  margin-bottom: 34px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: nowrap;
  }

  @media (min-width: 992px) {

  }

  a, span {
    display: block;
  }
}

.main-map__contacts-icon {
  margin-bottom: 13px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.main-map__contacts-header {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
  letter-spacing: 0.3px;
}

.main-map__contacts-link {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 13px;
  letter-spacing: 0.3px;

  @media (min-width: 768px) {
    position: relative;
    top: 3px;
    margin-bottom: 15px;
  }

  &:not(:hover) {
    color: $text-main;
  }
}

.main-map__contacts-additional {
  font-weight: 400;
  font-size: 14px;
  color: $text-muted;
  letter-spacing: 0.5px;
}

.main-map__contacts-email {
  .main-map__contacts-header {
    margin-bottom: 9px;
  }

  .main-map__contacts-link {

  }
}

.main-map__contacts-button {
  margin-top: 6px;

  .btn {
    min-width: 200px;
  }
}

.main-map__city-button {
  pointer-events: auto;

  @media (min-width: 992px) {
    position: absolute;
    top: -20px;
    right: 30px;
    min-width: 200px;
    width: fit-content;
  }
  
  .dropdown {
    .btn {
      width: 100%;
    }
  }
}


.about + .main-map {
  padding-top: 30px;

  @media (min-width: 768px) {
    padding-top: 42px;
  }
}

.callback-block + .main-map {
  padding-top: 31px;

  @media (min-width: 768px) {
    padding-top: 50px;
  }
}

body.page-contacts {
  .main-map {
    padding-top: 0;
  }
}