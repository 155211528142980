/*TEXT*/
$text-main: #494949;
$text-black: #000000;

$text-main-light: #4D4D4D;
$text-footer: #666666;
$text-muted: #A4A4A4;

$text-accent-light: #876F67;

$text-input: #4D4D4D;
$text-placeholder: #A4A4A4;

$text-primary: #E53935;


/*ACCENT*/
$accent: #a50034;
$accent-callback: #cc0041;

$accent-2: #cc0041;

$accent-filter: invert(48%) sepia(77%) saturate(548%) hue-rotate(335deg) brightness(96%) contrast(95%); // #EF6F2E;
$accent-filter-2: invert(37%) sepia(22%) saturate(4493%) hue-rotate(356deg) brightness(96%) contrast(85%); // #CE571A;


$accent-hover: $accent-2;

$accent-link: $accent;
$accent-link-hover: $accent-2;

$svg-accent-color: $accent;

/*BACKGROUNDS*/
$body-bg: #F5F5F5;
$header-bg: $body-bg;
$header-bg-open-menu: #fff;
$main-bg-light: #F5F5F5;
$main-bg-mid: #F1F1F1;


/*MAIN BUTTONS*/
$btn-bg: $accent;

$btn-bg-hover: $accent-2;
$btn-border-color: $accent;

$btn-txt: #fff;
$btn-txt-hover: #fff;


/*INPUTS*/
$input-bg: #fff;
$input-bg-focus: darken($main-bg-mid, 2%);
$input-border: #E5E5E5;


/*CHECKBOXES*/
$checkbox-border: #2E2826;
$checkbox-bg: #2E2826;


/*RADIOS*/
$radio-border: #2E2826;
$radio-bg: $accent;


/*FOOTER*/
$footer-bg: #fff;
$footer-text: #A4A4A4;
$placeholder-text: #A4A4A4;


// MISC
$separate-line: #E5E5E5;
$mobile-menu-bg: #fff;

$border-line-color: #e5e5e5;

$transition: all .2s ease;
$container-padding-x: 0.625rem;
$container-md-padding-x: 1.5rem;
$container-xxl-padding-x: 55px;

$border-radius-min: 20px;
$border-radius: 60px;
$slide-radius: 20px;
$slide-bg: #fff;

$box-shadow: 0px 0px 15px rgba(51, 51, 51, 0.03);
$box-shadow-hover: 0 0 6px 2px rgba(51, 51, 51, 0.10);
$box-shadow-gray: rgba(51, 51, 51, 0.05);
$accent-shadow: rgba($accent, 0.25);

.mm-menu {
  --mm-color-icon: #4D4D4D;
  --mm-color-text-dimmed: #4D4D4D;
}

.mm-menu--theme-light, .mm-menu--theme-dark {
  //--mm-color-background: #f3f3f3;
  //--mm-color-border: rgb(0 0 0 / 0.15);
  //--mm-color-icon: rgb(0 0 0 / 0.4);
  //--mm-color-text: rgb(0 0 0 / 0.8);
  //--mm-color-text-dimmed: rgb(0 0 0 / 0.4);
  //--mm-color-background-highlight: rgb(0 0 0 / 0.05);
  //--mm-color-background-emphasis: rgb(255 0 0 / 75%);
  //--mm-color-focusring: #06c;
}

.mm-menu--theme-light {
  background-color: #fff;
}

/*Bootstrap variables customization*/

// Create your own map
$custom-colors: (
    "accent": $accent
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

$btn-border-radius: 0px;
$btn-font-size: 17px;
$btn-padding-y: 0.25rem;
$btn-padding-x: 1.1rem;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1280px
);

$container-max-widths: (
    sm: 540px,
    md: 745px,
    lg: 960px,
    xl: 1140px,
    xxl: 1250px
);

$input-padding-y: 13px;
$input-padding-x: 20px;
$input-font-size: 17px;
$input-color: $placeholder-text;
$input-group-addon-bg: #fff;
$input-border-radius: 12px;
$input-border-color: $input-border;

// M-Menu

:root {
  --mm-size: 100%;
  --mm-max-size: 576px;
  //--mm-spn-item-indent: 55px;
}


.mm-listitem {
  color: $text-main;

  a {

  }

  .mm-btn--next {
    font-weight: 600;
  }

  .mm-btn--next:after {
    border: 2px solid $text-main;
    border-bottom: none;
    border-right: none;
  }

  .mm-btn--prev:before {

  }

}

/*

.mm-spn.mm-spn--light {
  color: $main-txt;
  background: $mobile-menu-bg;
}

.mm-spn.mm-spn--navbar {
  &::before {
    //left: 55px !important;
  }

  ul {
    top: calc(var(--mm-spn-item-height) - 5px);
  }
}

.mm-spn a, .mm-spn {
  padding: 13px 20px 12px !important;
}

.mm-spn span {
  font-weight: 600;
}

.mm-spn.mm-spn--navbar ul:before {
  z-index: 10;
}
*/


@mixin setColor($baseColor) {
  color: $baseColor;

  &:hover {
    color: darken($baseColor, 7%);
  }
}

@mixin setBgColor($baseColor) {
  background-color: $baseColor;

  &:hover {
    background-color: darken($baseColor, 10%);
  }
}

