.header {
  background-color: $header-bg;

  a {
    &:hover {
      color: $accent-link;
    }
  }

  .header-top-part {
    padding: 16px 0 17px;

    @media (min-width: 768px) {
      padding: 7px 0 9px;
      border-bottom: 1px solid $border-line-color;
    }

    @media (min-width: 992px) {
      padding: 7px 0 9px;
    }
  }

  .header__additional-logos {
    display: flex;

    div {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
        margin-left: 31px;
        position: relative;

        &::before {
          position: absolute;
          content: '';
          top: calc(50% - 7px);
          left: -31px;
          width: 1px;
          height: calc(100% - 4px);
          background-color: #BDBDBD;
        }
      }
    }
  }

  .header__additional-links {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 2px;
    left: -3px;

    a {
      font-size: 14px;

      &:not(:last-child) {
        margin-right: 33px;
      }
    }
  }

  .header-top-part__menu {

    @media (max-width: 991px) {
      margin-left: 22px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    @media (min-width: 992px) {
      display: flex;
    }

    @media (min-width: 1200px) {
      //margin-left: 50px;
    }

    a {
      @media (max-width: 991px) {
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }
      }


      &:not(:first-child) {
        margin-left: 10px;
      }

      &:first-child {
        padding: 12px 29px;

        @media (min-width: 992px) {
          padding: 12px 12px;
        }

        @media (min-width: 1280px) {
          padding: 12px 29px;
        }

      }
    }
  }

  a {
    color: $text-main;
  }

  .navbar {
    padding: 0;
    justify-content: space-between;
    flex-wrap: nowrap;

    @media (min-width: 992px) {
      position: static;
    }
  }

  .header-navbar-element {
    display: flex;
    flex-grow: 1;

    @media (min-width: 992px) {
      flex-grow: inherit;
    }

    > * {
      align-items: center;
    }

    &:first-child {
      @media (min-width: 992px) {
      }
    }
  }

  .header-navbar-phone {
    margin: 0 0 0 auto;
  }

  .header-navbar-element--buttons {
    @media (min-width: 992px) {

    }

    @media (min-width: 1200px) {

    }
  }

  .navbar__logo-wrap {
    display: flex;
    flex-wrap: nowrap;

    @media (min-width: 992px) {
      margin-right: 30px;
    }
  }

  .header-logo-additional-text {
    font-weight: 500;
    font-size: 14px;
    opacity: 0.5;
    position: relative;
    top: 2px;
  }

  .navbar-brand {
    padding: 0;
    margin-left: 8px;
    margin-right: 0;
    position: relative;

    @media (min-width: 375px) {
      margin-left: 15px;
      margin-right: 5px;
    }

    @media (min-width: 768px) {
      margin-left: 20px;
      margin-right: 25px;
    }

    @media (min-width: 992px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .navbar-brand-logo {
    > img {
      width: 96px;
      height: 36px;
      position: relative;
      top: -4px;
    }
  }

  .navbar-brand__text {
    position: absolute;
    right: -3px;
    font-size: 9px;
    font-weight: 500;
    display: block;
    text-align: center;
    margin-top: 3px;
    bottom: -8px;

    @media (min-width: 992px) {
      right: -3px;
    }
  }

  .header-location-element {
    span {
      font-size: 12px;
      color: $accent;
    }
  }

  .nav-link__menu-title {
    //position: relative;
    //top: 1px;
  }

  .header-top-part__link.btn {
    padding: 12px 31px;
    font-size: 16px;

    @media (min-width: 992px) {
      padding: 12px 12px;
    }

    @media (min-width: 1280px) {
      padding: 12px 31px;
    }
  }

  .header-top-part__link--status {
    @media (min-width: 992px) {
      display: inline-block;
      text-align: center;
    }

    @media (min-width: 1200px) {
      margin-bottom: 0;
    }
  }

  .header-top-part__link:not(.btn) {


    @media (min-width: 992px) {

    }

    @media (min-width: 1200px) {

    }
  }

  .dropdown-toggle {

    &::before {
      @media (min-width: 992px) {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% + 30px);
        background-color: transparent;
        z-index: 2;
      }
    }

    &::after {
      margin-left: 0.5em;
      vertical-align: 0.1em;

      @media (min-width: 992px) {
        content: none;
      }
    }
  }

  .dropdown-menu {
    overflow: hidden;
    border-radius: 0;
    font-size: 14px;
    background-color: $header-bg-open-menu;

    @media (max-width: 991px) {
      border: none;

      .dropdown-item {
        font-size: 14px;
        padding: 5px 22px 5px 50px;

        @media (min-width: 992px) {
          padding: 0.25rem 1rem;
        }
      }

      .dropdown-item:focus, .dropdown-item:hover {
        background: transparent;
      }

      .dropdown-item.active, .dropdown-item:active {
        background: #e9ecef;
      }
    }

    @media (min-width: 992px) {
      top: calc(100% + 27px);
      border: none;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    .btn {
      padding: 10px 25px;
    }

    &[aria-labelledby="dropdown-links-2"] {
      @media (min-width: 992px) {
        top: calc(100% + 9px);
        padding-bottom: 10px;
        //border: 1px solid $border-line-color;
      }
    }
  }

  .dropdown-menu  {
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.1);
    border-radius: $border-radius-min;
  }

  .dropdown-menu:not(.first-menu) {
    @media (min-width: 992px) {
      left: 13px;
    }
  }

  .dropdown-menu__repair {
    //max-height: 300px;

    @media (min-width: 992px) {
      display: flex;
      flex-wrap: wrap;
      //max-width: 944px;
      width: 100%;
      margin: 0 auto;
      padding: 0 20px 0 23px;
    }

    @media (min-width: 992px) {
      min-width: 300px;
    }

    .row {
      @media (min-width: 992px) {
        width: 100%;
      }

      @media (min-width: 1280px) {
        width: calc(100% + 15px);
        margin-left: -15px;
        margin-right: -15px;
      }

      [class^="col"] {
        padding: 0 15px;

        &:nth-child(2) {
          .dropdown-menu__group {
            @media (min-width: 1280px) {
              //padding-left: 15px;
            }
          }
        }
      }
    }
  }

  .dropdown-menu__group {

    @media (min-width: 992px) {
      margin-right: 30px;
      //min-width: 180px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media (min-width: 1200px) {
      margin-right: 60px;
    }

    &--popular {
      p, .dropdown-menu__group-title {
        color: $accent !important;
      }
    }

    .dropdown-item__all-services {
      color: $accent;
    }

    p, .dropdown-menu__group-title {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 19px;
      white-space: nowrap;
      color: $text-main;

      @media (min-width: 1200px) {
        font-size: 16px;
      }
    }

    .dropdown-item {
      font-weight: 400;
      font-size: 16px;
      padding: 2px 0;
      color: $text-main-light;
      position: relative;

      @media (min-width: 992px) {
        padding: 0;
        white-space: break-spaces;
        display: flex;
      }

      @media (min-width: 1200px) {
        //padding: 2px 17px 2px 17px;
        font-size: 16px;
      }

      img {
        max-width: 45px;
      }

      span {
        margin-left: 22px;
        letter-spacing: 0.4px;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-self: center;
        position: relative;
        top: 2px;
      }
    }

    .row {
      width: 100%;
    }

     ul {
       padding: 0;
       list-style: none;
       margin: 0;

       li {
         margin-bottom: 0;

         @media (min-width: 992px) {
           margin-bottom: 19px;
         }

         &:last-child {
           @media (min-width: 992px) {
             margin-bottom: 0;
           }
         }
       }
     }

    .dropdown-menu__more {
      font-weight: 600;
      font-size: 14px;
      display: inline-block;
      margin-top: 7px;

      @media (min-width: 1200px) {
        font-size: 16px;
        padding-left: 17px;
      }
    }
  }

  .mobile-address {
    color: #000;
    max-width: 240px;
    display: inline-block;
    font-size: 14px;

    @media (min-width: 768px) {
      max-width: 180px;
      font-size: 14px;
    }
  }

  .contacts-info-mobile {
    display: flex;
    flex-direction: column;

    a {
      //min-width: 40px;
      //min-height: 40px;
      display: flex;
      justify-content: center;
      //margin: 0 5px;

      @media (min-width: 768px) {
        font-weight: 500;
      }

      svg {
        width: 17px;
        height: 17px;
        //align-self: center;
        margin-right: 5px;

        @media (min-width: 375px) {
          width: 20px;
          height: 20px;
        }
      }

      &:first-child {
        span {
          font-weight: bold;
        }
      }

      span {
        font-size: 12px;
        align-self: center;
      }
    }
  }

  .contacts-info {
    display: flex;
    align-items: baseline;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: column;
      max-width: 300px;
      margin-right: 26px;
      margin-left: auto;
    }

    @media (min-width: 992px) {
      flex-direction: column;
    }

    a {
      font-weight: 600;
      margin-left: auto;
      text-align: right;

      @media (min-width: 992px) {
        margin-right: 0;
        width: 100%;
        font-size: 15px;
      }

      @media (min-width: 1200px) {
        font-size: 16px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .header-fullphone {
      display: none;

      @media (min-width: 768px) {
        display: block;
        font-weight: 500;
        font-size: 18px;
      }
    }

    .header-location-element {
      position: relative;
      top: -1px;

      @media (min-width: 992px) {
        top: -2px;
      }
    }

  }

  .contacts-info__address {
    margin-right: 0;
    margin-left: auto;
    text-align: right;
    color: $text-main;

    @media (max-width: 575px) {
      font-size: 12px;
      line-height: 12px;
    }

    @media (min-width: 375px) {
      //display: block;
      //max-width: 240px;
    }

    @media (min-width: 576px) {
      display: block;
      max-width: 240px;
    }

    @media (min-width: 768px) {
      max-width: 300px;
    }

    @media (min-width: 992px) {
      font-size: 14px;
    }

    @media (min-width: 1200px) {
      max-width: 500px;
      //font-size: 16px;
    }
  }

  .navbar-brand-text {
    margin-left: 9px;

    @media (min-width: 768px) {
      margin-left: 12px;
    }

    span {
      &:not(:first-child) {
        margin-left: 1px;

        @media (min-width: 768px) {
          margin-left: 2px;
        }
      }

      &:nth-child(1) {
        img {
          position: relative;
          top: -2px;
          max-width: 35px;
          max-height: 13px;

          @media (min-width: 768px) {
            top: 1px;
            max-width: 49px;
            max-height: 18px;
          }
        }
      }

      &:nth-child(2) {
        img {
          position: relative;
          top: -2px;
          max-width: 47px;
          max-height: 10px;

          @media (min-width: 768px) {
            top: -1px;
            max-width: 68px;
            max-height: 16px;
          }
        }
      }
    }
  }

  .navbar-brand-additional {
    display: inline-block;
    margin-top: 5px;
    font-size: 8.5px;
    position: relative;
    top: -1px;

    @media (min-width: 768px) {
      font-size: 12px;
      top: 0;
    }
  }

  .navbar-location {
    @media (max-width: 991px) {
      margin-top: 20px;
      margin-bottom: 16px;
      display: block;
    }

    span {
      font-size: 14px;

      &:last-child {
        position: relative;
        top: 1px;
      }
    }
  }

  .navbar-toggler {
    border-color: transparent;
    box-shadow: none;
    padding: 0 0 0 0;
    position: relative;
    left: -2px;
    border-radius: 0;
    background-color: transparent;
    border: none;
    outline: none;

    &.menu-close-button {
      top: calc(50% - 14px);
      //left: -4px;
    }

    &:hover, &:active, &:focus-visible {
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
    }

    .navbar-toggler-hamburger {
      display: block;
      flex-direction: column;
      justify-content: space-between;
      width: 25px;
      height: 25px;
      transition: $transition;
      position: relative;
      
      .hamburger-line {
        display: block;
        width: 20px;
        height: 2px;
        background-color: #000;
        position: absolute;
        left: 2px;

        &--1 {
          top: 5px;
        }

        &--2 {
          top: 11px;
        }

        &--3 {
          top: 17px;
        }
      }
    }

    &[aria-expanded="true"] {

      .navbar-toggler-hamburger {
        transition: $transition;

        span {
          width: 110%;
        }

        .hamburger-line {

          &--1 {
            top: 14px;
            transform: rotate(45deg);
            transition: all .2s ease;
          }

          &--2 {
            top: 14px;
            opacity: 0;
            transform: translateX(10px);
            transition: all .2s ease;
          }

          &--3 {
            bottom: 14px;
            transform: rotate(-45deg);
            transition: all .2s ease;
          }
        }
      }
    }

  }

  .header-bottom-part {
    //border-top: 1px solid #E2E2E2;
    position: relative;
    padding: 20px 0 14px;
    color: $header-bg;

    @media (min-width: 768px) {
      padding: 20px 0 21px;
    }

    @media (min-width: 992px) {
      padding: 26px 0 26px;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #D9D9D9;
      opacity: 0.2;
      z-index: 2;

      @media (min-width: 992px) {
        content: "";
      }
    }

    .navbar-collapse.show {

    }

    .navbar-collapse {
      position: relative;

      @media (min-width: 992px) {
        position: static;
      }

      &.show {
        &::before {
          opacity: 1;
        }
      }

      @media (min-width: 992px) {

      }
    }

    .navbar-nav {
      @media (max-width: 991px) {
        margin-bottom: 16px;
      }

      @media (min-width: 992px) {
        width: 100%;
        justify-content: center;
      }

      .nav-item {
        &.nav-item__devices {

        }
        
        .nav-link {
          font-size: 15px;
          padding-left: 21px;
          padding-right: 22px;
          white-space: break-spaces;
          color: $text-main;

          @media (min-width: 992px) {
            padding: 8px 8px 8px 8px;
          }

          @media (min-width: 1200px) {
            padding: 8px 13px 8px 13px;
          }

          span {
            position: relative;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.4px;

            @media (min-width: 1200px) {
              font-size: 14px;
            }
          }

          &__menu {
            display: flex;
            position: relative;

            @media (min-width: 992px) {
              top: 3px;
            }

            @media (min-width: 1200px) {
              top: 1px;
            }
          }

          &__menu-toggler {
            width: 20px;
            height: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-right: 14px;
            top: -2px;

            .nav-link__menu-square {
              width: 9px;
              height: 9px;
              background-color: $accent;
              border-radius: 2px;
              position: absolute;
              transition: all .15s ease;

              &:nth-child(1) {
                top: 0;
                left: 0;
              }

              &:nth-child(2) {
                top: 0;
                right: 0;
              }

              &:nth-child(3) {
                bottom: 0;
                left: 0;
              }

              &:nth-child(4) {
                bottom: 0;
                right: 0;
              }
            }

            + .nav-link__menu-title {
              color: $accent;
            }
          }
        }

        &:hover {
          .nav-link, .nav-link[aria-expanded="true"] {
            color: $accent;
          }
        }
      }
    }
  }

  .header-bottom-discount {
    background-color: $accent-2;
    padding: 10px 0 12px;
    transform: translateX(0);
    transition: $transition;
    color: #fff;

    @media (min-width: 768px) {
      padding: 11px 0 12px;
    }

    @media (min-width: 992px) {
      padding: 14px 0 17px;
    }
    
    p {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0.2px;

      @media (min-width: 992px) {
        font-size: 14px;
      }

      @media (min-width: 1200px) {
        letter-spacing: 0.3px;
      }
    }

    a {
      color: inherit;
    }
  }

  .dropdown-menu {
    margin: 0;
    padding-bottom: 0;

    &.first-menu {
      padding-top: 19px;
      padding-bottom: 19px;
      left: 13px;
    }
    
    &.show {
      //background-color: green;
    }
  }

  .dropdown-item.active, .dropdown-item:active {
    background-color: $accent;
    color: $text-main;
  }

  .dropdown-item:hover, .dropdown-item:focus {
    background-color: transparent;
    color: $accent;
  }

  .header-search {
    position: relative;
    flex-grow: 1;

    @media (max-width: 991px) {
      margin-bottom: 16px;
    }

    @media (min-width: 992px) {
      margin-left: 0;
    }

    @media (min-width: 1200px) {
      margin-left: 34px;
    }

    &::before {
      position: absolute;
      content: '';
      top: calc(50% - 13px);
      left: 2px;
      width: 1px;
      height: 29px;
      background-color: $border-line-color;
    }

    input {
      padding-left: 59px;
      padding-right: 21px;
      border: none;
      outline: none;
      box-shadow: none;
      font-weight: 400;
      font-size: 14px;
      background-color: transparent;

      @media (max-width: 991px) {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      @media (min-width: 992px) {
        padding: 21px 30px 19px 60px;
      }
    }

    input[type="text"]::-webkit-input-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #A4A4A4;
    }
    input[type="text"]::-moz-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #A4A4A4;
    }
    input[type="text"]:-ms-input-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #A4A4A4;
    }
    input[type="text"]:-moz-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #A4A4A4;
    }

    button {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 60px;
      background: transparent;
      border: none;
      box-shadow: none;

      svg, path {
        transition: all .2s ease;
        position: relative;
        left: 2px;


      }

      &:hover {
        svg, path {
          fill: #8a8a8a;
          transition: all .2s ease;
        }
      }
    }
  }
}


@keyframes slideIn {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}




.hamburger.menu-close-button {
  left: 10px;
  pointer-events: none;
  opacity: 0;
  position: absolute;
}

.mm-ocd__content {
  background: transparent;
}

#mobile-menu__wrapper {
  .header nav {
    //display: none;
  }

  .header {
    //text-align: center;
  }

  .header.fixed {
    //position: fixed;
    //top: 0;
    //left: 0;
  }
}

#mobile-menu {
  //position: relative;
}

.mm-spn {
  li {
    &:after {
      margin: 0;
    }
  }

  &.mm-spn--navbar {
    &::before {
      //top: 30px;
    }

    &::after {
      //padding: 0 calc(var(--mm-spn-item-indent) * 3);
      //height: 63px;
      //line-height: 63px;
    }

    ul {
      //top: calc(var(--mm-spn-item-height) + 14px);
    }
  }
}

.mm-page {
  min-height: unset;
  position: static;
}

.mm-panel#mm-1 {
  .mm-navbar {
    display: none;
    a {
      color: red;
    }
  }
}


.mm-listitem {
  &:last-child,
  &:nth-last-child(2) {
    &::after {
      content: none !important;
    }

    a {

    }
  }

  a {
    font-size: 14px;

    &:hover {
      color: $accent;
    }
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}

.mm-panel#mm-1,
.mm-panel#mm-3 {
  .mm-listitem a {
    font-weight: 500;
    font-size: 16px;
  }
}

.mm-listitem:not(.mobile-menu__additional-item) {
  a {
    padding: 16px 20px 22px 20px;
  }
}

.mm-listitem--submenu {


}

.mm-panels {
  background-color: transparent;
  position: relative;
}

.mm-panel {
  //margin-top: 78px;
}

.mm-panel--parent {
  & + .mm-panel--opened {
    .mm-btn--next {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}



.mobile-menu__additional-item {
  padding-bottom: 17px;
}

.mobile-menu__additional {
  //top: 460px;
  //position: absolute;
  //z-index: 546;
  //width: 100%;
  transition: $transition;
  padding: 26px 10px 25px 10px;
  opacity: 1;
  width: 100%;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    bottom: 0;
    height: 1px;
    width: calc(100% - 20px);
    background-color: $border-line-color;
    opacity: 0.12;
    left: 10px;
  }

  .menu-close-button {
    position: static;
    z-index: 1;
    opacity: 1;
  }


  .additional-address__location,
  .additional-phone__number,
  .additional-email__address {
    //font-size: 14px;
    padding: 0;
    margin: 0;
  }

  .btn {
    max-width: fit-content;
  }
}

.mobile-menu__search {
  margin-bottom: 24px;
}

.mobile-menu__additional-phone {
  .contacts-info {
    display: flex;
    flex-direction: column;

    a {
      font-weight: 500;
      font-size: 16px;
      transition: $transition;

      &:hover {
        color: $accent;
      }
    }
  }
}

.callback-button {
  .btn {
    padding: 4px 24px 6px;
    font-size: 14px;
  }
}

.mobile-menu__additional-city {
  margin-bottom: 7px;

  a {
    .accent-icon {
      margin-right: 6px;
    }

    span {
      font-weight: 500;
      font-size: 12px;
      color: $accent;
    }
  }
}

.mobile-menu__additional-map {
  a {
    .accent-icon {
      margin-right: 6px;
    }

    span {
      font-weight: 500;
      font-size: 12px;
      color: $accent;
    }
  }
}

.additional-address__text,
.additional-phone__text,
.additional-email__text {
  //font-size: 12px;
  margin-bottom: 0;
  //font-weight: bold;
}


.mm-panel {
  background-color: $header-bg;
}

.mm-navbar {
  opacity: 1;
  transition: all .1s ease;
  transition-delay: 0.04s;
  //border-color: transparent;
  background: transparent;
  font-size: 16px;
  border-width: 0px;

  &::after {
    position: absolute;
    content: "";
    bottom: -1px;
    left: 0;
    width: calc(100% - 0px);
    height: 1px;
    background-color: $border-line-color;
    opacity: 1;
  }

  a {
    //color: $accent;

    &::before {

    }
  }
}

.mm-btn:not(.mm-hidden)+.mm-navbar__title:last-child {
  padding-inline-start: 0;
  padding-left: 0;
}

.mm-btn.mm-btn--prev.mm-navbar__btn {
  width: 40px;
}

.mm-btn--prev:before {
  inset-inline-start: 13px;
}

.mm-navbar {
  //position: absolute;
  //width: 0;
  //height: 0;
}

.mm-panel--opened {
  .mm-navbar {

  }
}

.mm-panel--parent {
  & + .mm-panel--parent {
    .mm-navbar {
      //position: static;
      //width: auto;
      //height: auto;
    }
  }
}

.mm-panel--parent {
  & + .mm-panel {
    .mm-navbar {
      //position: static;
      //width: auto;
      //eight: auto;
    }
  }

  .mm-navbar {
    opacity: 0;
    transition: all .1s ease;
    transition-delay: 0.04s;
  }

  & + .mm-panel--opened {
    .mm-navbar {
      //position: static;
      //top: 0;
      //display: flex;
    }
  }
}

.mm-panel--highest {
  .mm-navbar {
    opacity: 0;
  }
}

#mobile-menu:not(.mm-menu--opened) {
  position: fixed;
  top: 74px;
  opacity: 0;
  transition: all 0.2s ease;
  border-top: 1px solid $border-line-color;

  @media (min-width: 768px) {
    top: 76px;
  }
}

#mobile-menu {
  top: 74px;
  width: 100%;
  border-top: 1px solid $border-line-color !important;

  @media (min-width: 768px) {
    top: 76px;
  }

  .form-control-search {
    input {
      background-color: #f5f5f5;
    }
  }

  .mm-listitem:after {
    width: calc(100% - 0px);
    background-color: $border-line-color;
    opacity: 0.22;
    left: 0;
    content: "";
  }
}

.mm-link--phone {
  padding: 21px 20px 3px 20px !important;


}

.mm-link--modal {
  padding: 5px 20px 15px 20px !important;
  letter-spacing: 0.6px;
  font-weight: 600;
  font-size: 16px;

  &.menu-close-button {
    opacity: 0.15;
    z-index: 3;
    width: 100%;
  }
}

.mm-listitem .mm-btn--next:after {
  right: 25px;
  top: calc(50% - 26px);

  @media (min-width: 768px) {
    right: 35px;
  }
}

.mm-wrapper__blocker {
  opacity: 0;
}

.mm-listitem__btn:not(.mm-listitem__text) {
  border-color: transparent;
}

.menu-close-button {
  position: absolute !important;
  z-index: -1;
  opacity: 0;
}


.mm-navbar__title {
  justify-content: flex-start;
}

body.mm-wrapper--opened {

  .header {
    transition: 0s;

    .header-bottom-part > .container::before {
      left: 0;
      width: 100%;
    }
  }

  .navbar-toggler {
    .navbar-toggler-hamburger {
      opacity: 0;
      transition: $transition;
    }

    &.menu-close-button {
      z-index: 2;
      opacity: 1;

      .navbar-toggler-hamburger {
        background-image: url('../images/icons/hamburger-open.svg');
        opacity: 1;
        transition: $transition;
        position: relative;
        z-index: 2;
      }
    }
  }

  .header-bottom-discount {
    opacity: 0;
    user-select: none;
    pointer-events: none;
    transform: translateX(-100%);
    transition: $transition;
    height: 0;
    display: none;
  }

}


body.page-contacts {
  .header {
    position: static;
  }
}