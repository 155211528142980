.callback-block {
  padding-top: 0;
  padding-bottom: 39px;
  background-color: $main-bg-light;
  overflow: hidden;

  @media (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 50px;
  }

  @media (min-width: 992px) {
    position: relative;
  }

  .section-title {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 600;
    line-height: 1.32;

    @media (min-width: 768px) {
      margin-bottom: 16px;
      font-size: 30px;
      letter-spacing: 0.8px;
    }
  }

  .section-subtitle {
    letter-spacing: 0.5px;
    line-height: 1.3;
    font-weight: 500;
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 16px;
      max-width: 350px;
    }
  }

  .callback-block__wrap {
    margin: 0 auto;
  }

  .callback-block__row {

  }

  .callback__form {
    margin: 36px auto 0;

    @media (min-width: 768px) {
      margin-top: 37px;
      max-width: 408px;
      margin-left: 0;
    }

    @media (min-width: 992px) {
      padding: 0;
    }

    .row {
      @media (min-width: 768px) {
        margin: 0 -5px;
      }

      [class^="col"] {
        @media (min-width: 768px) {
          padding: 0 5px;
        }
      }
    }

  }

  .form-control {
    color: $text-main;
  }

  .form-control:focus {
    //background-color: $input-bg-focus;
  }

  .request-form__element {
    margin-bottom: 10px;

    button[type="submit"] {
      width: 100%;
      padding: 14px 16px 12px;
      font-size: 14px;
      letter-spacing: 0.3px;
      font-weight: 800;
    }
  }

  .request-form__element-btn {
  }

  .callback__textarea {
    height: 100px;
  }

  .callback__acceptance {
    margin-top: 4px;
    font-size: 10px;
    opacity: .7;
    letter-spacing: .3px;
    line-height: 15px;


    a {
      color: #fff;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #fff;
      }
    }
  }
}

.callback-block__plate {
  background-color: $accent-callback;
  border-radius: $border-radius-min;
  color: #fff;
  padding: 38px 29px 258px 32px;
  position: relative;
  overflow: hidden;
  background-image: url('../images/icons/percentage.svg');
  background-position: left calc(50% - 62px) bottom -27px;
  background-repeat: no-repeat;
  background-size: 258px;

  @media (min-width: 768px) {
    padding: 55px 190px 55px 39px;
    background-position: left calc(50% + 287px) bottom -57px;
  }

  @media (min-width: 1200px) {
    padding: 55px 200px 55px 59px;

    background-image: url('../images/icons/percentage.svg'), url('../images/icons/percentage.svg');
    background-position: right calc(50% - 177px) bottom 19px, right calc(50% - 452px) top -26px;
    background-repeat: no-repeat, no-repeat;
    background-size: 355px, 204px;
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: -35px;
    width: calc(100% + 100px);
    height: calc(100% + 100px);
    background-image: url('../images/icons/percentage.svg');
    background-position: right calc(50% - 179px) bottom 189px;
    background-repeat: no-repeat;
    background-size: 150px;
    transform: rotate(6deg);
    opacity: 0.8;
    pointer-events: none;

    @media (min-width: 768px) {
      top: -6px;
      background-position: right calc(50% - 358px) top -5px;
    }

    @media (min-width: 1200px) {
      top: -6px;
      background-position: right calc(50% - 460px) bottom 375px;
      background-size: 105px;
      transform: rotate(28deg);
    }
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: -35px;
    width: calc(100% + 100px);
    height: calc(100% + 100px);
    background-image: url('../images/icons/percentage.svg');
    background-position: right calc(50%  - 81px) bottom 61px;
    background-repeat: no-repeat;
    background-size: 40px;
    transform: rotate(-9deg);
    opacity: 0.4;
    pointer-events: none;

    @media (min-width: 768px) {
      background-position: right calc(50% - 268px) top 28px;
      opacity: .3;
    }

    @media (min-width: 1200px) {
      background-position: right calc(50% - 583px) bottom 109px;
      opacity: .3;
      background-size: 58px;
      transform: rotate(-13deg);
    }
  }
  
  .callback-block__row {
    @media (min-width: 576px) {
      max-width: 400px;
      margin: 0 auto;
    }

    @media (min-width: 768px) {
      max-width: unset;
    }
  }
}

.main-map + .feedback-block {
  padding-top: 20px;
}

.feedback-block {
  overflow: hidden;

  .callback-block__plate {
    background-image: none;
    position: static;
    padding: 38px 29px 45px 32px;

    @media (min-width: 768px) {
      padding: 56px 39px 56px 38px;
    }

    @media (min-width: 992px) {
      padding: 35px 59px 68px 57px;
    }
  }

  .callback-block__plate::before,
  .callback-block__plate::after {
    display: none;
  }

  .callback-block__row {
    @media (min-width: 768px) {
      max-width: 643px;
    }

    @media (min-width: 992px) {
      max-width: 100%;
    }
  }
  
  .callback-block__wrap {
    @media (min-width: 992px) {
      max-width: 320px;
      margin-left: 0;
      margin-top: 88px;
    }
  }

  .callback__form {
    max-width: 100%;
    margin-top: 36px;

    .callback__submit-button {
      margin-top: -4px;
    }
  }

  .callback__acceptance {
    @media (min-width: 992px) {
      max-width: 240px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-size: 12px;
    }

    a {
      display: inline-block;
    }
  }
}

.how-we-work + .callback-block {

}
