.popular-categories + .about {
  padding-top: 18px;
  padding-bottom: 70px;

  @media (min-width: 768px) {
    padding-top: 32px;
  }

  @media (min-width: 1200px) {
    padding-top: 28px;
    padding-bottom: 96px;
  }
}

.about {
  padding-top: 0;

  .section-title {
    margin-bottom: 17px;
    font-size: 24px;
    font-weight: 600;

    @media (min-width: 768px) {
      font-size: 30px;
      margin-bottom: 34px;
    }

    @media (min-width: 992px) {
      margin-bottom: 35px;
    }
  }
}

.about__plate {
  background: #fff;
  border-radius: $border-radius-min;
  overflow: hidden;
}

.about__content-wrap {
  padding: 41px 31px 0 31px;

  @media (min-width: 768px) {
    padding: 56px 50px 0 52px;
  }

  @media (min-width: 992px) {
    padding: 56px 32px 45px 46px;
  }

  @media (min-width: 1200px) {
    padding: 86px 89px 97px 78px;
  }
}

.about--with-icon {
  .about__content-wrap {
    padding: 80px 31px 0 31px;

    @media (min-width: 768px) {
      padding: 56px 55px 0 50px;
    }

    @media (min-width: 992px) {
      padding: 45px 40px 45px 70px;
    }

    @media (min-width: 1200px) {
      padding: 85px 70px 98px 102px;
    }
  }

  .section-title {
    position: relative;

    img {
      position: absolute;
      left: 0;
      top: -40px;
      width: 20px;
      height: 20px;

      @media (min-width: 768px) {
        position: static;
        margin-right: 14px;
      }
    }
  }
}

.about__text {
  margin: 0 auto;

  p {
    font-size: 14px;
    margin-bottom: 19px;
    line-height: 1.365;

    @media (min-width: 768px) {
      font-size: 16px;
      margin-bottom: 23px;
    }

    .accent {
      font-weight: 600;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    margin-top: 18px;
    padding-left: 21px;

    li {
      font-size: 14px;
      letter-spacing: 0.3px;
      margin-bottom: 2px;

      @media (min-width: 768px) {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
  }
}

.about__button--wrap {
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 495px) {
    max-width: 100%;
  }
}

.about__button {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -6px -3px -6px;

  @media (min-width: 768px) {
    margin-top: 40px;
  }

  @media (min-width: 992px) {
    margin-top: 40px;
  }

  a {
    width: 100%;
    padding: 10px 15px 12px;
    margin: 6px;

    &:first-child {
      @media (min-width: 495px) {
        max-width: 155px;
      }

      @media (min-width: 992px) {
        max-width: 240px;
      }

      @media (min-width: 1152px) {
        max-width: 155px;
      }

    }

    &:nth-child(2) {
      @media (min-width: 495px) {
        max-width: 240px;
      }

      @media (min-width: 992px) {
        max-width: 240px;
      }

      @media (min-width: 1152px) {
        max-width: 240px;
      }
    }

    &.btn--outline {
      border-color: #A4A4A4;
    }
  }
}

.about__img-wrap {
  margin-top: 38px;

  @media (min-width: 768px) {
    margin-top: 48px;
  }

  @media (min-width: 992px) {
    margin-top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.about__img {

  @media (min-width: 992px) {
    height: 100%;
  }

  img {
    @media (min-width: 992px) {
      object-fit: cover;
      height: 100%
    }
  }
}

.about--text {
  padding-top: 59px;

  @media (min-width: 768px) {
    padding-top: 56px;
  }

  h3 {
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.45px;
    margin-bottom: 17px;

    &:not(:first-of-type) {
      margin-top: 24px;

      @media (min-width: 768px) {
        margin-top: 32px;
      }
    }

    @media (min-width: 768px) {
      font-size: 30px;
      margin-bottom: 26px;
    }
  }

  p {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

.about__row {
  [class^="col"] {
    &:first-child {
      @media (min-width: 992px) {
        width: 53.3%;
      }
    }

    &:last-child {
      @media (min-width: 992px) {
        width: 46.7%;
      }
    }
  }
}

.about--left-img {
  @media (min-width: 495px) {
    .about__button a {
      max-width: fit-content;
    }
  }


  .about__img {
    height: 62.5vw;

    @media (min-width: 768px) {
      height: 52vw;
    }

    @media (min-width: 992px) {
      height: 100%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      @media (min-width: 768px) {
        object-position: 50% 50%;
      }

      @media (min-width: 992px) {
        width: unset;
        object-position: 47% 50%;
      }
    }
  }
}

.about__steps {
  margin-top: 44px;

  @media (min-width: 576px) {
    position: relative;
  }

  &::before {
    @media (min-width: 576px) {
      position: absolute;
      content: '';
      top: 10px;
      left: 29px;
      bottom: 10px;
      width: 1px;
      height: auto;
      background-color: #494949;
    }
  }

  & + .about__text {
    .about__button {
      margin-top: -7px;

      @media (min-width: 576px) {
        margin-top: 0;
      }

      a {
        @media (min-width: 576px) {
          margin-left: 6px;
          margin-top: 9px;
          max-width: 172px;
        }
      }
    }
  }
}

.about__steps-item {
  margin-bottom: 38px;

  @media (min-width: 576px) {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    z-index: 2;
  }

  &:first-child {
    &::before {
      @media (min-width: 576px) {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 60px;
        height: calc(50% - 0px);
        background-color: #fff;
        z-index: -1;
      }
    }
  }

  &:last-child {
    &::before {
      @media (min-width: 576px) {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 60px;
        height: calc(50% - 0px);
        background-color: #fff;
        z-index: -1;
      }
    }
  }
}

.about__steps-icon {
  min-width: 59px;
  min-height: 59px;
  max-width: 59px;
  max-height: 59px;
  border: 1px solid #494949;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;

  @media (min-width: 576px) {
    align-self: center;
    margin-bottom: 0;
    margin-right: 29px;
  }

  img {
    height: 30px;
    width: 30px;
    margin: 0 auto;
  }
}

.about__steps-info {
  p {
    font-size: 14px;

    @media (min-width: 576px) {
      font-size: 16px;
      max-width: 325px;
      line-height: 1.3;
    }
  }
}

.about__steps-number {
  font-weight: 800;
  color: $accent;
  display: block;
  margin-bottom: 21px;
}

.about__steps-title {
  font-weight: 600;
  font-size: 14px;
  display: block;
  margin-bottom: 12px;

  @media (min-width: 576px) {
    font-size: 16px;
    margin-bottom: 9px;
  }
}

.about--steps {
  .about__content-wrap {
    @media (min-width: 992px) {
      padding: 56px 65px 68px 47px;
    }
  }

  .about__img {
    img {
      object-position: center;

      @media (min-width: 992px) {
        object-position: 11% 0;
      }

      @media (min-width: 1200px) {
        object-position: 4% 0;
      }
    }
  }
}

body.page-about {
  .about--sc {
    padding-bottom: 0;

    .section-title {
      font-weight: 600;
      font-size: 30px;
      margin-bottom: 24px;

      @media (min-width: 768px) {
        font-size: 36px;
      }

      @media (min-width: 1200px) {
        margin-bottom: 35px;
      }
    }

    .about__content-wrap {
      padding: 36px 31px 0 31px;

      @media (min-width: 768px) {
        padding: 53px 42px 0 50px;
      }

      @media (min-width: 992px) {
        padding: 56px 32px 45px 46px;
      }

      @media (min-width: 1200px) {
        padding: 84px 50px 98px 103px;
      }
    }

    .about__img-wrap {
      @media (min-width: 768px) {
        margin-top: 38px !important;
      }

      @media (min-width: 992px) {
        margin-top: 0 !important;
      }
    }

    .about__button {
      margin: 30px -6px -3px -6px;

      @media (min-width: 768px) {
        margin: 32px -6px -3px -6px;
      }

      @media (min-width: 1200px) {
        margin: 40px -6px -3px -6px;
      }

      a {
        &:first-child {
          @media (min-width: 1152px) {
            max-width: fit-content;
          }
        }
      }
    }
  }
}

.text-block--split {
  padding-bottom: 68px !important;

  @media (min-width: 768px) {
    padding-bottom: 98px !important;
  }

  @media (min-width: 992px) {
    padding-top: 99px !important;
  }

  .section-title {
    @media (min-width: 992px) {
      margin-top: -12px;
    }
  }

  .main-text14 {
    @media (min-width: 992px) {
      max-width: 555px;
    }
  }

  .about__button {
    margin: 22px -6px -3px -6px;

    @media (min-width: 768px) {
      margin: 41px -6px -3px -6px;
    }

    a {
      max-width: fit-content;
      padding: 10px 22px 12px;
    }
  }
}