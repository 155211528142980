@import "theme/bootstrap";
@import "fonts";
@import "theme/mixins";
@import "global/base";
//@import "theme/icons";


* {
	//outline: 1px solid rgba(255, 0, 0, 0.3);
}

html {
	scroll-behavior: smooth;
}

html,
body {
	min-width: 320px;
	width: 100%;
	background-color: $body-bg;
	color: $text-main;
	font-family: 'Manrope', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 1.195; // 19px
	letter-spacing: 0.4px;
}

body {
	//smooth page appearing
	opacity: 0;
	animation-name: bodySmoothAppearing;
	animation-delay: .3s;
	animation-duration: .5s;
	animation-fill-mode: forwards;
	//smooth page appearing
}

@keyframes bodySmoothAppearing {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

// blocks
@import "blocks/header";
@import "blocks/first-block";
@import "blocks/mini-features";
@import "blocks/popular-categories";
@import "blocks/about";
@import "blocks/mini-gallery";
@import "blocks/callback";
@import "blocks/how-we-work";
@import "blocks/faq";
@import "blocks/main-map";
@import "blocks/footer";
@import "blocks/modals";

@import "blocks/price-block";
@import "blocks/text-block";
@import "blocks/certs";
//@import "blocks/contacts-block";

