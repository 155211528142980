.price-block {
  padding-top: 59px;
  padding-bottom: 16px;
  margin-top: 12px;

  @media (min-width: 768px) {
    padding-top: 84px;
    padding-bottom: 31px;
    margin-top: -3px;
  }

  @media (min-width: 1200px) {
    padding-top: 59px;
    padding-bottom: 29px;
  }

  .section-title {
    font-weight: 600;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 36px;
      margin-bottom: 28px;
    }
  }

  .main-text14 {
    @media (min-width: 1200px) {
      //max-width: 848px;
    }
  }
}

.price-block__wrapper {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: -59px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;

    @media (min-width: 768px) {
      top: -84px;
    }

    @media (min-width: 1200px) {
      top: -59px;
    }
  }
}

.price-block__title {
  @media (min-width: 768px) {
    margin-bottom: 37px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 29px;
  }
}

.price-block__price-table-wrap {
  margin-top: 35px;

  @media (min-width: 768px) {
    margin-top: 46px;
  }

  @media (min-width: 1200px) {
    margin-top: 48px;
  }
}

.price-block__table {
  overflow: hidden;

  .row {
    [class^='col'] {
      &:first-child {
        @media (min-width: 768px) {
          width: 47%;
          position: relative;

          &::before {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background-color: #D9D9D9;
          }
        }

        @media (min-width: 1200px) {
          width: 67.7%;
        }
      }

      &:nth-child(2) {
        @media (min-width: 768px) {
          width: 24%;
        }

        @media (min-width: 1200px) {
          width: 15%;
        }
      }

      &:nth-child(3) {
        @media (min-width: 768px) {
          width: 22.1%;
          position: relative;

          &::before {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background-color: #D9D9D9;
          }
        }

        @media (min-width: 1200px) {
          width: 13.1%;
        }
      }

      &:last-child {
        @media (min-width: 768px) {
          width: 6%;
          padding: 0;
        }

        @media (min-width: 1200px) {
          width: 3%;
        }
      }
    }
  }
}

.tabs-block {
  .price-block__table {
    .price-block__table-row {
      @media (min-width: 992px) and (max-width: 1199px) {
        padding: 13px 8px 13px;
      }

      [class^='col'] {
        &:first-child {
          @media (min-width: 768px) {
            width: 54%;
            position: relative;

            &::before {
              position: absolute;
              content: '';
              top: 0;
              right: 0;
              width: 1px;
              height: 100%;
              background-color: #D9D9D9;
            }
          }

          @media (min-width: 992px) {
            width: 40%;
          }

          @media (min-width: 1200px) {
            width: 44.5%;
          }

          @media (min-width: 1280px) {
            width: 45.5%;

            &::before {
              right: -1px;
            }
          }
        }

        &:nth-child(2) {
          @media (min-width: 768px) {
            width: 24%;
          }

          @media (min-width: 992px) {
            width: 31%;
          }

          @media (min-width: 1200px) {
            width: 28.8%;
          }

          @media (min-width: 1280px) {
            width: 27.8%;
          }
        }

        &:nth-child(3) {
          @media (min-width: 768px) {
            width: 18.66666667%;
            position: relative;

            &::before {
              position: absolute;
              content: '';
              top: 0;
              right: 0;
              width: 1px;
              height: 100%;
              background-color: #D9D9D9;
            }
          }

          @media (min-width: 992px) {
            width: 26%;
          }

          @media (min-width: 1200px) {
            width: 22.95%;
          }

          @media (min-width: 1280px) {
            width: 22.95%;

            &::before {
              right: 4px;
            }
          }
        }

        &:last-child {
          @media (min-width: 768px) {
            width: 3%;
            padding: 0;
          }

          @media (min-width: 1200px) {
            //width: 9.88888888%;
          }
        }
      }
    }
  }
}

.price-block__table-header {
  color: #fff;
  background-color: #333;
  padding: 0 10px;
  display: none !important;

  @media (min-width: 768px) {
    padding: 0 20px;
  }

  @media (min-width: 1200px) {
    padding: 0 30px;
  }
}

.price-block__table-title {
  padding: 14px 0 15px;
  display: inline-block;
}

.price-block__table-body {
  max-height: 711px;

  @media (min-width: 768px) {
    max-height: 538px;
  }

  .simplebar-content-wrapper {
    overflow-x: hidden !important;
  }

  .simplebar-track.simplebar-horizontal {
    display: none;
  }

  .simplebar-scrollbar.simplebar-visible {
    &::before {
      opacity: 0.2;
      transition: $transition;
    }
  }
}

.simplebar-dragging {
  .simplebar-scrollbar.simplebar-visible {
    &::before {
      opacity: 0.5;
      transition: $transition;
    }
  }
}

.price-block__table-row {
  transition: $transition;
  padding: 0 8px;
  margin: 0;
  border-radius: $border-radius-min;

  @media (max-width: 767px) {
     position: relative;
  }

  @media (min-width: 768px) {
    padding: 15px 19px 16px 18px;
    border-radius: 10px;

  }

  @media (min-width: 1200px) {
    //padding: 0 30px;
  }

  &:nth-child(odd) {
    background-color: #fff;
  }

  &:nth-child(even) {
    background-color: #F5F5F5;
  }

  &:hover {
    background-color: #e5e5e5;
    transition: $transition;

    .price-block__value--service {
      color: $accent-link-hover;
    }
  }
}

div.price-block__table-row-primary:nth-of-type(odd) {
  background-color: #41a946 !important;
}

div.price-block__table-row-primary:nth-of-type(even) {
  background-color: #66BB6A !important;
}

div.price-block__table-row-primary .price-block__value {
  color: #fff;
}

div.price-block__table-row-primary .price-block__value--more::before,
div.price-block__table-row-primary .price-block__value--more::after {
  background-color: #fff;
}

div.price-block__table-row-primary {
  //background-color: #2e9133 !important;
  .price-block__value--more {
    pointer-events: none;
  }


}

div.price-block__table-row-primary:hover .price-block__value--service {
  color: #fff;
}

.price-block__value {
  padding: 12px 0 12px;
  display: inline-flex;
  height: 100%;

  @media (min-width: 768px) {
    padding: 0;
    font-weight: 500;
  }

  span {
    align-self: center;
  }

  &--service {
    &:not(:hover) {
      color: $text-main;
    }
    @media (max-width: 767px) {
      padding: 14.5px 35px 2px 0;
      font-weight: 500;
      font-size: 16px;
    }
  }

  &--time {
    font-size: 16px;

    @media (max-width: 767px) {
      padding: 2px 0 16px;
      font-size: 14px;
      font-weight: 500;
    }

    @media (min-width: 768px) {
      padding: 0 0 0 17px;
    }

    @media (min-width: 992px) {
      padding: 0;
    }

    @media (min-width: 1200px) {
      padding: 0 0 0 17px;
    }
  }

  &--price {
    font-size: 16px;
    font-weight: 600;
    color: $accent;

    @media (max-width: 767px) {
      padding: 10px 0 2px;
    }

    @media (min-width: 768px) {
      padding: 0 0 0 18px;
    }

    @media (min-width: 992px) {
      padding: 0;
    }

    @media (min-width: 1200px) {
      padding: 0 0 0 17px;
    }
  }

  &--request {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;

    &:not(:hover) {
      color: #333;
    }

    &::before {
      position: absolute;
      content: "";
      top: calc(50% - (42% / 2));
      left: -20px;
      width: 1px;
      height: 42%;
      background-color: #000;

      @media (min-width: 768px) {
        left: -12px;
      }

      @media (min-width: 1200px) {
        left: -20px;
      }
    }
  }

  &--more {
    position: relative;

    @media (min-width: 768px) {
      padding: 0;
      right: -21px;
    }

    @media (min-width: 1200px) {
      right: -15px;
    }

    span {
      align-self: center;
      display: inline-block;
      width: 15px;
      height: 15px;
    }

    @media (max-width: 767px) {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: calc(100% + 1px);
      display: block;
      z-index: 2;
      font-size: 0;
      cursor: pointer;
    }

    &::before {
      position: absolute;
      content: "";
      top: calc(50% - 3px);
      right: 21px;
      width: 7px;
      height: 1px;
      background-color: $accent;
      transform: rotate(45deg);
      transition: $transition;

      @media (min-width: 768px) {
        right: 6px;
      }
    }

    &::after {
      position: absolute;
      content: "";
      top: calc(50% + 2px);
      right: 21px;
      width: 7px;
      height: 1px;
      background-color: $accent;
      transform: rotate(-45deg);
      transition: $transition;

      @media (min-width: 768px) {
        right: 6px;
      }
    }

    &:hover {

      &::before {
        transform: rotate(45deg) translateX(5px) translateY(-5px);
        transition: $transition;
      }

       &::after {
        transform: rotate(-45deg) translateX(5px) translateY(5px);
         transition: $transition;
      }
    }

  }
}