.mini-features + .remont-categories {
  padding-top: 0;
}

.remont-categories {
  padding-bottom: 0;

  @media (min-width: 1200px) {
    padding-bottom: 20px;
  }
  
  .row {
    @media (min-width: 576px) {
      margin: 0 -5px;
    }

    @media (min-width: 768px) {
      margin: 0 -10px;
    }


    [class^="col"] {
      margin-bottom: 20px;

      @media (min-width: 576px) {
        padding: 0 5px;
      }

      @media (min-width: 768px) {
        padding: 0 10px;
      }

      @media (min-width: 1200px) {
        padding: 0 10px;
        margin-bottom: 20px;
      }
    }
  }
}


.remont-categories__row {
  @media (min-width: 1200px) {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin: 0 !important;
  }

}

.remont-categories__col {
  @media (min-width: 1200px) {
    padding: 0 !important;
    margin: 0 !important;
    width: unset !important;
    display: grid !important;

    &:first-child {
      grid-area: 1 / 1 / 3 / 3;
    }

    &:nth-child(2) {
      grid-area: 1 / 3 / 2 / 4;
    }

    &:nth-child(3) {
      grid-area: 1 / 4 / 2 / 5;
    }

    &:nth-child(4) {
      grid-area: 2 / 3 / 3 / 4;
    }

    &:nth-child(5) {
      grid-area: 2 / 4 / 3 / 5;
    }

    &:nth-child(6) {
      grid-area: 3 / 1 / 4 / 2;
    }

    &:nth-child(7) {
      grid-area: 3 / 2 / 4 / 3;
    }

    &:nth-child(8) {
      grid-area: 3 / 3 / 4 / 4;
    }

    &:nth-child(9) {
      grid-area: 3 / 4 / 4 / 5;
    }
  }

  &:first-child {
    @media (min-width: 1200px) {
      .remont-categories__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .remont-categories__img {
        min-width: 374px;
        max-width: 374px;
      }

      .remont-categories__title {
        margin-top: 45px;
        position: static;
        flex-grow: 0;

        p {
          font-size: 24px;
        }
      }
    }
  }
}

.remont-categories__item {
  border-radius: $border-radius-min;
  display: flex;
  background-color: #fff;
  padding: 17px 20px 16px 19px;
  position: relative;

  @media (min-width: 576px) {
    height: 100%;
    overflow: hidden;
    padding: 17px 24px 16px 24px;
  }

  @media (min-width: 768px) {
    min-height: 235px;
    display: block;
    padding: 27px 25px 36px 30px;
    position: relative;
  }

  @media (min-width: 1200px) {
    min-height: 330px;
    padding: 15px 30px 60px 30px;
  }

  @media (min-width: 1280px) {
    min-height: 360px;
    padding: 0 30px 60px 30px;
  }


  &::before, &::after {
    position: absolute;
    content: '';
    //width: 7px;
    width: 7px;
    height: 2px;
    background-color: $text-main;
    right: 20px;
    transition: $transition;

    @media (min-width: 768px) {
      display: none;
    }
  }

  &::before {
    top: calc(50% - 3px);
    transform: rotate(45deg);
  }

  &::after {
    bottom: calc(50% - 3px);
    transform: rotate(-45deg);
  }

  &:hover {
    color: $text-main;

    span.link-with-arrow {
      color: $accent-2;

      &::before, &::after {
        background-color: $accent-2;
      }

      &::before {
        transform:  translateX(5px) rotate(45deg);
        transition: $transition;
      }

      &::after {
        transform:  translateX(5px) rotate(-45deg);
        transition: $transition;
      }
    }
  }
}

.remont-categories__img {
  width: 16vw;
  margin-right: 7%;
  //height: calc(10vw * 2.8);

  @media (min-width: 576px) {
    width: unset;
    min-width: 16vw;
    max-width: 16vw;
    margin-right: 10px;
  }

  @media (min-width: 768px) {
    min-width: 220px;
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
  }

  @media (min-width: 1200px) {
    min-width: 190px;
    max-width: 190px;
    margin-bottom: 0;
  }

  @media (min-width: 1280px) {
    min-width: 100%;
    max-width: 100%;
  }
}


.remont-categories__title {
  flex-grow: 1;
  align-self: center;
  //position: absolute;
  //left: calc(50% - 60px);

  @media (min-width: 768px) {

  }

  @media (min-width: 1200px) {
    position: absolute;
    width: 100%;
    bottom: 36px;
    left: 0;
  }

  p {
    margin-bottom: 0;
    font-weight: 800;
    font-size: 14px;

    @media (min-width: 768px) {
      font-weight: 800;
      font-size: 18px;
      text-align: center;
      margin-bottom: 28px;
      letter-spacing: 0;
      max-width: 230px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: 1200px) {
      margin-bottom: 17px;
    }
  }

  span {
    @media (min-width: 768px) {
      display: inline-block !important;
      font-size: 14px;
      text-align: center;
      font-weight: 600;
    }
  }

  .link-with-arrow {
    display: none;

    &.link-arrow-icon {
      &::before {
        bottom: 4px;

        @media (min-width: 576px) and (max-width: 767px) {
          bottom: 1px;
        }

        @media (min-width: 768px) {
          bottom: 3px;
        }
      }
    }
  }
}