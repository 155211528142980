.mini-features + .text-block {
  padding-top: 74px;

  @media (min-width: 768px) {
    padding-top: 82px;
    padding-bottom: 71px;
  }

  @media (min-width: 1200px) {
    padding-top: 86px;
  }
}

.text-block {
  padding-top: 58px;
  padding-bottom: 43px;

  @media (min-width: 768px) {
    padding-top: 55px;
    padding-bottom: 65px;
  }

  @media (min-width: 1200px) {
    padding-bottom: 46px;
  }

  & + .text-block {
    //border-top: 1px solid $border-line-color;
    //border-bottom: none;
  }

  &:last-of-type {
    border-bottom: none;
  }

  .text-block__wrapper {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: -60px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #E5E5E5;

      @media (min-width: 768px) {
        top: -82px;
      }

      @media (min-width: 1200px) {
        top: -83px;
      }
    }
  }

  .section-title {
    font-weight: 600;
    margin-bottom: 18px;

    @media (min-width: 768px) {
      font-size: 36px;
      line-height: 1.4;
      margin-bottom: 31px;
      letter-spacing: 0.6px;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.45px;
    margin-bottom: 17px;

    &:not(:first-of-type) {
      margin-top: 24px;

      @media (min-width: 768px) {
        margin-top: 32px;
      }
    }

    @media (min-width: 768px) {
      font-size: 30px;
      margin-bottom: 26px;
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 18px;

    @media (min-width: 768px) {
      font-size: 24px;
    }

    &:not(:first-child) {
      margin-top: 30px;

      @media (min-width: 768px) {
        margin-top: 38px;
      }
    }

    &:not(:last-child), &:not(:last-of-type) {
      margin-bottom: 22px;

      @media (min-width: 768px) {
        margin-bottom: 19px;
      }
    }
  }
}

.about--text + .text-block {
  border-top: 1px solid $border-line-color;

  @media (min-width: 1200px) {
    padding-bottom: 67px;
  }
}

.text-block {
  ul {
    padding-left: 21px;
  }

  & > ul {
    margin-top: 18px;
  }
}


.text-block--plate {
  .section-title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 27px;

    @media (min-width: 768px) {
      font-size: 36px;
      margin-bottom: 25px;
    }

    @media (min-width: 1200px) {
      margin-bottom: 31px;
    }
  }

  .text-block__wrapper {
    background-color: #fff;
    padding: 35px 30px 48px 30px;
    border-radius: $border-radius-min;

    @media (min-width: 768px) {
      padding: 53px 51px 68px 51px;
    }

    @media (min-width: 1200px) {
      padding: 82px 102px 98px 100px;
    }
  }

  .about__button {
    margin: 22px -6px -3px -6px;

    @media (min-width: 768px) {
      margin: 41px -6px -3px -6px;
    }

    @media (min-width: 1200px) {
      margin: 38px -6px -3px -6px;
    }

    a:first-child {
      @media (min-width: 495px) {
        max-width: fit-content;
      }
    }
  }
}


body.base {
  .text-block--plate {
    padding-top: 0;
    padding-bottom: 0;

    .text-block__wrapper {

    }
  }
}