
.modal {
  color: #333;

  .modal-dialog {
    @media (max-width: 399px) {
      margin: 0.6rem;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }
  }

  .modal-content {
    border: none;
    border-radius: $border-radius-min;
    max-width: 300px;
    margin: 0 auto;

    .modal-content__row {

      @media (min-width: 768px) {
        margin: 0;
      }

      .modal-content__col {
        @media (min-width: 768px) {
          padding: 0;
        }
        &:first-child {
          @media (min-width: 768px) {
            width: 42%;
            background-color: #fff;
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            position: static !important;
          }
        }

        &:last-child {
          @media (min-width: 768px) {
            width: 58%;
            //position: relative;
            //right: -4px;
          }
        }
      }
    }
  }

  .subtitle {
    margin-top: 16px;
    font-size: 16px;
    //line-height: 20px;
    font-weight: 500;
    text-align: center;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-body {
    padding: 0 16px 50px 16px;

    @media (min-width: 768px) {
      padding: 0 32px 46px 32px;
    }
  }

  .modal-header {
    position: relative;
    padding: 39px 16px 0 16px;
    border: none;

    @media (min-width: 768px) {
      padding: 39px 32px 0 32px;
      position: static !important;
    }
  }

  .modal-header__title {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 30.72px;
    text-align: center;
    width: 100%;
  }

  button.close {
    position: absolute;
    top: -45px;
    right: -10px;
    border: none;
    background-color: transparent;
    padding: 10px;

    span {
      display: flex;
    }

    svg {
      width: 15px;
      height: 15px;
    }

    svg, svg path {
      fill: #fff;
    }

    &:hover {

      svg {
        transform: rotate(90deg);
        transition: all .2s ease;
      }
    }
  }

  .callback__form {
    margin-top: 26px;
    padding: 0 14px;

    @media (min-width: 768px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .request-form__element {
    height: unset !important;
    margin-bottom: 10.5px !important;
  }

  .form-control {
    background-color: #F5F5F5;
  }

  .callback__submit-button {
    width: 100%;
    padding: 12px 30px 12px;
  }

  .modal__image {
    @media (min-width: 768px) {
      background-repeat: no-repeat;
      background-position: top 0px right -102px;
      background-size: 745px;
      width: 100%;
      height: 100%;
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }

  .callback__acceptance {
    font-size: 11px;
    font-weight: 400;
    color: $text-muted;
    text-align: center;
    margin-top: 5px;

    @media (min-width: 768px) {
      text-align: left;
    }

    a {
      text-decoration: underline;

      &:not(:hover) {
        color: $text-muted;
        opacity: 0.7;
      }
    }
  }

  &.callback {
    .subtitle {
      max-width: 214px;
      margin-left: auto;
      margin-right: auto;
    }

    .request-form__element {
      margin-bottom: 16px;
    }
  }

  &.review {
    .request-form__element {
      margin-bottom: 16px;
    }
  }

  .modal-alert-text {
    color: $accent;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
  }

  .modal-desc-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.8px;
  }

  &.success {
    background-color: rgba(0, 0, 0, 0.05);

    button.close {
      svg, path {
        //fill: #333 !important;
      }
    }

    .modal-header {
      padding: 0;
    }

    .modal-body {
      padding: 10px 18px 40px 18px;
    }

    * {
      position: relative;
      z-index: 11111; }

    .modal-title {
      margin-top: 30px;
      margin-bottom: 14px;
      font-size: 24px;
      line-height: 30.5px;
      font-weight: 600;
      text-align: center;
    }

    .card-body {
      flex: 1 1 auto;
      max-width: 450px;
      padding: 7px 12px;
      margin: 0 auto;
    }

    p {

    }

    .modal-alert-text {
      margin-bottom: 18px;
    }

    h4 {
      font-weight: bold;
      margin-top: 32px;
      margin-bottom: 10px; }

    .cross {
      padding: 10px;
      color: #000;
      cursor: pointer;

      .request__modal-close {
        position: relative;
        width: 20px;
        height: 20px;
        display: inline-block;
        color: transparent;
        z-index: 9999; }

      .request__modal-close:hover::before {
        transform: rotate(136deg);
        transition: all 0.3s ease; }

      .request__modal-close:hover::after {
        transform: rotate(45deg);
        transition: all 0.3s ease; }

      .request__modal-close::before,
      .request__modal-close::after {
        position: absolute;
        content: "";
        top: 8px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #777;
        transition: all 0.3s ease; }

      .request__modal-close::before {
        transform: rotate(45deg); }

      .request__modal-close::after {
        transform: rotate(-45deg); }
    }

    .continue:focus {
      outline: none; }

    .continue {
      border-radius: 5px;
      text-transform: capitalize;
      font-size: 13px;
      padding: 8px 19px;
      cursor: pointer;
      color: #fff !important;
      border-color: transparent;
      background-color: #D50000;
      font-weight: normal; }

    .continue:hover {
      background-color: #D32F2F !important; }

    .checkmark__icon {
      position: relative;
    }

    .checkmark__round {
      position: absolute;
      content: "";
      top: calc(50% - 40px);
      left: calc(50% - 40px);
      width: 80px;
      height: 80px;
      background-color: rgba(0,0,0,.05);
      border-radius: 50%;
      animation: scale-round 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
      //animation-delay: .7s;
      opacity: 0;
    }

    .checkmark__circle {
      position: relative;
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: $accent;
      fill: none;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards 3s;
    }

    .checkmark {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: block;
      stroke-width: 2;
      stroke: #fff;
      stroke-miterlimit: 10;
      margin: 10% auto;
      box-shadow: inset 0 0 0 $accent;
      animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    }

    .checkmark__check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards; }
  }

  &.status {
    .modal-content {
      max-width: 300px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  ul {
    padding: 0;
    list-style: none;
    margin: 0;

    li {
      margin-bottom: 8px;
    }

    a {
      font-size: 14px;

      &.popular-city {
        font-weight: 600;
      }

      &:not(:hover) {
        color: $text-main;
      }
    }
  }

  .cityChoose__content {
    @media (min-width: 768px) {
      //overflow-x: hidden;
    }

    ul {
      //max-height: 222px;
      height: 222px;
      //overflow-y: scroll;

      @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        //width: 570px;
      }
    }
    
    .simplebar-content {

      @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .simplebar-content:before,
    .simplebar-content:after {
      content: none;
    }
  }
}


@keyframes stroke {
  100% {
    stroke-dashoffset: 0; } }

@keyframes scale {
  0%, 100% {
    transform: none; }
  50% {
    transform: scale3d(1.1, 1.1, 1); } }

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 50px $accent; } }

@keyframes scale-round {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1.4);
    opacity: 1;
  }
}