.swiper {
  width: 100%;
  height: 100%;
  border-radius: $slide-radius;
}

.swiper-wrapper {
  height: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  height: auto;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.first-block {
  overflow: hidden;
  
  .container {
    position: relative;
  }

  .section-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.35;
    letter-spacing: 1px;
    margin-bottom: 24px;

    max-width: 320px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 576px) {
      max-width: 100%;
      margin-left: 0;
      font-size: 30px;
    }

    @media (min-width: 768px) {
      font-size: 36px;
      letter-spacing: 1.2px;
      margin-bottom: 33px;
    }

    @media (min-width: 1200px) {
      min-width: calc(100% + 40px);
    }

    & > span {
      font-weight: 600;
      line-height: 1.4;
      letter-spacing: 1px;

      @media (min-width: 768px) {
        letter-spacing: 1.2px;
      }
    }

  }
}

.first-block__plate {
  border-radius: $slide-radius;
  background-color: $slide-bg;
  padding: 34px 29px 51px 30px;
  text-align: left;
  width: 100%;
  cursor: pointer;
  height: 100%;
  user-select: none;

  @media (min-width: 576px) {
    padding: 34px 45px 51px 45px;
  }

  @media (min-width: 768px) {
    padding: 51px 20px 65px 50px;
  }

  @media (min-width: 992px) {
    padding: 80px 100px 85px 100px;
  }

  @media (min-width: 1200px) {
    min-height: 521px;
  }

  @media (min-width: 1280px) {
    min-height: 496px;
    padding: 68px 100px 67px 100px;
  }
}

.first-block__text-wrap {
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @media (min-width: 1200px) {
    margin-top: 14px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.365;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    @media (min-width: 576px) {
      max-width: 100%;
      margin-left: 0;
    }

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 1.36625;
      margin-top: auto;
      margin-bottom: auto;
    }

    @media (min-width: 1200px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.first-block__service-wrap {
  max-width: 320px;
  margin-top: 24px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    max-width: 400px;
    margin-left: 0;
    margin-top: -10px;
    margin-bottom: 25px;
  }
}

.first-block__service {
  display: flex;
  flex-wrap: wrap;
  margin-left: -9px;
  margin-right: -9px;

  > * {
    margin: 9px;
  }
}

.first-block__price {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 15px 24px 14px 20px;
  color: $accent;
  font-weight: 800;
  font-size: 18px;
  min-width: 152px;
  text-align: center;
}

.first-block__time {
  font-size: 18px;
  align-self: center;
}

.first-block__pluses {
  padding: 0;
  list-style: none;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    max-width: 100%;
    margin-left: 0;
  }

  li {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.45px;
    line-height: 1.365;

    @media (min-width: 768px) {
      font-size: 16px;
      letter-spacing: 0.5px;
    }
  }
}

.first-block__button {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;

  @media (min-width: 576px) {
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;
  }

  @media (min-width: 768px) {
    margin-top: 39px;
  }

  @media (min-width: 1200px) {
    margin-top: -60px;
  }

  .btn {
    padding: 12px 26px 12px;
    letter-spacing: 0.1px;
    width: 100%;
    margin: 5px 0;

    @media (min-width: 576px) {
      max-width: 228px;
      padding: 12px 20px 12px;
    }

    @media (min-width: 768px) {
      max-width: 209px;
      padding: 12px 14px 12px;
    }

    &:not(:last-child) {
      @media (min-width: 576px) {
        margin-right: 25px;
      }

      @media (min-width: 768px) {
        margin-right: 16px;
      }
    }
  }

  .btn--outline {
    @media (min-width: 576px) {
      max-width: 224px;
    }
  }
}

.first-block__info-col {
  @media (min-width: 825px) {
    max-width: 450px;
  }

  @media (min-width: 1200px) {
    max-width: 600px;
  }
}

.first-block__img-col {
  @media (min-width: 825px) {
    flex-grow: 1;
  }
}

.first-block__img {
  margin-top: 37px;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    max-width: 190px;
    margin-top: 15px;
    margin-left: 0;
  }

  @media (min-width: 768px) {
    max-width: 165px;
    margin-top: 0;
    margin-left: auto;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  @media (min-width: 825px) {
    max-width: 100%;
    width: 30vw;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  @media (min-width: 890px) {
    max-width: 270px;
  }

  @media (min-width: 1200px) {
    max-width: 365px;
  }
}

.first-block__img-wrap {
  @media (min-width: 768px) {
    max-width: 165px;
    position: relative;
    bottom: -2px;
    right: 10px;
  }

  @media (min-width: 825px) {
    max-width: 100%;
    bottom: -50px;
    right: 15px;
  }

  @media (min-width: 992px) {
    bottom: -35px;
    right: -16px;
  }

  @media (min-width: 1200px) {
    bottom: -1px;
    right: -5px;
  }
}

.swiper-pagination {
  bottom: 6px !important;

  @media (min-width: 576px) {
    bottom: 11px !important;
  }

  @media (min-width: 768px) {
    bottom: 11px !important;
  }

  @media (min-width: 1200px) {
    bottom: 11px !important;
  }
}

.swiper-pagination-bullet {
  transition: all .1s ease;
  opacity: 0.40;
}

.swiper-pagination-bullet-active {
  width: 16px;
  border-radius: 5px;
  background-color: #000;
  opacity: 1;
  transition: all .1s ease;
}

.swiper-navigation {
  position: absolute;
}

.swiper-button-next-unique,
.swiper-button-prev-unique {
  position: absolute;
  bottom: 15px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease;
  width: 50px;
  height: 50px;
  opacity: 0;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.1);

  &:hover {
    transition: all .2s ease;

    svg path {
      fill: $accent
    }
  }

  @media (min-width: 576px) {
    top: calc(50% - 25px);
  }
}

.first-block {
  .container:hover {
    @media (min-width: 576px) {
      .swiper-button-prev-unique,
      .swiper-button-next-unique {
        opacity: 1;
        transition: all .2s ease;
      }
    }
  }
}

.swiper-button-next-unique {
  right: calc(50% - 110px);
  left: auto;

  &:hover {
    transform: translateX(3px);
  }

  @media (min-width: 576px) {
    right: 0;
  }

  @media (min-width: 768px) {
    right: 4px;
  }

  @media (min-width: 1280px) {
    right: 30px;
  }
}

.swiper-button-prev-unique {
  left: calc(50% - 110px);
  right: auto;

  &:hover {
    transform: translateX(-3px);
  }

  @media (min-width: 576px) {
    left: 0;
  }

  @media (min-width: 768px) {
    left: 4px;
  }

  @media (min-width: 1280px) {
    left: 30px;
  }
}

.first-block--service {

  .first-block__plate {
    @media (min-width: 1200px) {
      padding: 68px 100px 94px 100px;
    }
  }

  .about__button--wrap {
    @media (min-width: 495px) {
      max-width: 320px;
    }

    @media (min-width: 576px) {
      max-width: 100%;
    }

    @media (min-width: 1200px) {
      margin-top: -99px;
    }
  }

  .first-block__img {
    @media (min-width: 768px) {
      max-width: 200px;
    }

    @media (min-width: 1200px) {
      max-width: 370px;
    }

    .first-block__img-wrap {
      @media (min-width: 768px) {
        max-width: 200px;
        bottom: 6px;
        right: -12px;
      }

      @media (min-width: 1200px) {
        max-width: 370px;
      }
    }
  }

  .first-block__button {
    margin-top: -60px;
  }

  .about__button {
    a {
      &:first-child {
        @media (min-width: 495px) {
          max-width: 240px;
        }

        @media (min-width: 576px) {
          max-width: fit-content;
        }
      }
    }
  }
}

.first-block--device {
  .first-block__button {
    @media (min-width: 1200px) {
      margin-top: -100px;
    }
  }
}