.how-we-work {
  padding-top: 17px;
  padding-bottom: 65px;
  overflow: hidden;

  @media (min-width: 768px) {
    padding-top: 25px;
  }

  .section-title {
    margin-bottom: 24px;

    @media (min-width: 768px) {
      font-size: 36px;
      font-weight: 600;
      letter-spacing: 1.1px;
      margin-bottom: 39px;
    }
  }

  .container {
    position: relative;
  }

  .swiper-button-next-unique,
  .swiper-button-prev-unique {
    opacity: 1 !important;
    bottom: -61px;

    @media (min-width: 576px) {
      top: calc(50% + 4px);
      bottom: auto;
    }

    @media (min-width: 768px) {
      top: calc(50% + 20px);
    }
  }

  .swiper-button-prev-unique {
    left: calc(50% - 61px);

    @media (min-width: 576px) {
      left: -8px;
    }

    @media (min-width: 768px) {
      left: 0;
    }
  }

  .swiper-button-next-unique {
    right: calc(50% - 61px);

    @media (min-width: 576px) {
      right: -8px;
    }

    @media (min-width: 768px) {
      right: 0;
    }
  }
}

.howWeWorkSlider {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: calc(50% - 1px);
    left: 0;
    width: 100%;
    height: 1px;
    border: 1px solid #E5E5E5;
  }

  .swiper-slide {
    text-align: left;
    padding-bottom: 0;
  }
}


.how-we-work__step {
  background-color: #fff;
  padding: 25px 25px 25px 30px;
  border-radius: $border-radius-min;

  @media (min-width: 768px) {
    padding: 44px 25px 47px 30px;
  }

  @media (min-width: 992px) {
    height: 100%;
    position: relative;
    z-index: 2;
  }
}

.how-we-work__title {
  color: $accent;
  font-weight: 600;
  font-size: 16px;
  display: block;
  margin-bottom: 21px;
}

.how-we-work__text {
  font-size: 14px;
  line-height: 1.3;
}

.how-we-work__wrap {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: calc(50% - 1px);
    left: 0;
    width: 100%;
    height: 1px;
    border: 1px solid #E5E5E5;
  }

  .row {
    margin: 0 -10px;

    [class^="col"] {
      padding: 0 10px;
    }
  }
}

body.inner {
  .how-we-work {
    padding-bottom: 71px;

    @media (min-width: 768px) {
      padding-bottom: 19px;
    }

    @media (min-width: 1200px) {
      padding-bottom: 23px;
    }

    .section-title {
      margin-bottom: 19px;

      @media (min-width: 768px) {
        margin-bottom: 40px;
      }
    }

    .swiper-button-next-unique,
    .swiper-button-prev-unique {
      bottom: -51px;
    }
  }
}