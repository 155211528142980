@font-face {
	font-family: 'Manrope';
	src: url('../fonts/Manrope-Regular.woff2') format('woff2'),
			 url('../fonts/Manrope-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Manrope';
	src: url('../fonts/Manrope-Medium.woff2') format('woff2'),
			 url('../fonts/Manrope-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Manrope';
	src: url('../fonts/Manrope-SemiBold.woff2') format('woff2'),
			 url('../fonts/Manrope-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Manrope';
	src: url('../fonts/Manrope-ExtraBold.woff2') format('woff2'),
			 url('../fonts/Manrope-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}