.cert {
  padding-top: 59px;
  padding-bottom: 70px;

  @media (min-width: 1200px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .section-title {
    @media (min-width: 768px) {
      margin-bottom: 30px;
    }

    @media (min-width: 1200px) {
      margin-top: 56px;
    }
  }

  .row {
    [class^="col"] {
      &:first-child {
        @media (min-width: 768px) {
          width: 59.8%;
        }

        @media (min-width: 1200px) {
          width: 49.7%;
        }
      }

      &:last-child {
        @media (min-width: 768px) {
          width: 40.2%;
        }

        @media (min-width: 1200px) {
          width: 50.3%;
        }
      }
    }
  }
}

.cert__info {
  @media (min-width: 1280px) {
    max-width: 455px;
  }

  p {
    letter-spacing: 0.3px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      margin-bottom: 29px;
    }
  }

  ul.list-style-check {
    margin-top: 23px;
  }
}

.cert__image {
  margin-top: 37px;

  @media (min-width: 768px) {
    margin-top: 23px;
  }

  @media (min-width: 1200px) {
    margin-top: 0;
  }

  a {
    display: block;
    max-width: 350px;
    text-align: center;
    margin: 0 auto;

    @media (min-width: 768px) {
      max-width: 302px;
    }

    @media (min-width: 1200px) {
      max-width: 387px;
      position: relative;
      left: -18px;
    }
  }
}