.mini-features {
  padding-top: 40px;
  padding-bottom: 23px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 51px;
  }

  @media (min-width: 1200px) {
    padding-bottom: 29px;
  }

  .row {
    @media (min-width: 1200px) {
      margin: 0;
    }

    [class^="col"] {
      margin-bottom: 37px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: 768px) {
        margin-bottom: 46px;
      }

      @media (min-width: 992px) {
        margin-bottom: 0;
      }

      &:nth-last-child(2) {
        @media (min-width: 768px) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.mini-features__wrap {
  max-width: 300px;
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: unset;
  }
}

.mini-features__item {
  text-align: center;

  @media (min-width: 992px) {
    max-width: 285px;
  }
}

.mini-features__icon-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.mini-features__icon {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0;
}

.mini-features__title {
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 11px;
  letter-spacing: 0.2px;
}

.mini-features__text {
  word-break: break-word;
  font-size: 14px;
  //max-width: 186px;
  margin: 0 auto;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 18px;
}

body.inner:not(.base) {
  .mini-features__icon-wrap {
    @media (min-width: 992px) {
      justify-content: flex-start;
    }
  }

  .mini-features__item {
    @media (min-width: 992px) {
      text-align: left;
    }
  }
}