.faq {
  padding-top: 54px;
  padding-bottom: 68px;

  @media (min-width: 768px) {
    padding-top: 18px;
    padding-bottom: 100px;
  }

  @media (min-width: 1200px) {
    padding-bottom: 98px;
  }
}

.faq .section-title {
  margin-bottom: 24px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
    font-size: 36px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 39px;
  }
}

@media (min-width: 992px) {
  .faq__title.title-line::before {
    left: 0; } }

.faq .faq__item {
  border-bottom: 1px solid #E5E5E5;

  &:first-of-type {
    border-top: 1px solid #E5E5E5;
  }
}
.faq .faq__item:first-child {
  border-top: 1px solid #f2f2f2; }

.faq .faq__item.active .item__head::before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.faq .faq__item.active .item__title {

}

.faq .faq__item.active .item__text {
  margin-bottom: 10px;
  transition: all .2s ease; }

.faq .item__head {
  padding-right: 35px;
  position: relative; }

.faq .item__head::before {
  content: '';
  position: absolute;
  top: calc(50% - 5px);
  background-image: url('../images/icons/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  right: 0px;
  display: block;
  width: 10px;
  height: 10px;
  -webkit-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;

  @media (min-width: 576px) {
    width: 15px;
    height: 15px;
    top: calc(50% - 7px);
    right: 1px;
  }
}


.faq .item__wrap {
  cursor: pointer;
  padding: 16px 0 6px 0;

  @media (min-width: 576px) {
    padding: 23px 0 12px 0;
  }
}

.faq .item__title {
  font-size: 14px;
  margin-bottom: 10px;

  @media (min-width: 576px) {
    font-size: 18px;
    margin-bottom: 14px;
  }
}

.faq .item__text {
  overflow: hidden;
  transition: all .2s ease;
  font-size: 14px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
}
