body {
  position: relative;
}

a {
  color: $text-main;
  text-decoration: none;

  &:hover {
    color: $accent-link-hover;
    text-decoration: none;
  }
}

a, button, input[type="button"] {
  transition: $transition;

  &:hover {
    transition: $transition;
  }

  .accent {
    color: $accent;

    &:hover {
      color: $accent-hover;
    }
  }
}

svg.accent-icon, svg.accent-icon path {
  fill: $svg-accent-color;
}

.accent {
  color: $accent !important;
}

a.accent {
  color: $accent-link !important;

  &:hover {
    color: $accent-link-hover !important;
  }
}

*, *::before, *::after {
  box-sizing: border-box;
}

main {
  display: block;
  /*overflow-x: hidden;*/

  section {
    overflow: hidden;
  }
}

p,ul,ol {
  &:last-child {
    margin-bottom: 0;
  }
}

ul {

  &.list-style-none {
    padding: 0;
    list-style: none;
  }

  &.list-style-custom {
    li {
      position: relative;
      padding-left: 16px;

      &::before {
        position: absolute;
        content: "";
        top: 9px;
        left: 0;
        width: 7px;
        height: 2px;
        background: $accent;
      }
    }
  }

  &.list-style-check {
    list-style: none;
    padding: 0;

    li {
      position: relative;
      padding-left: 0;
      padding-top: 30px;
      min-height: 30px;
      font-weight: 500;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      letter-spacing: 0.3px;

      @media (min-width: 768px) {
        padding-top: 0;
        padding-left: 30px;
      }

      &:not(:last-child) {
        margin-bottom: 24px;

        @media (min-width: 768px) {
          margin-bottom: 19px;
        }
      }

      &::before {
        position: absolute;
        content: "";
        top: 3px;
        left: 0;
        width: 15px;
        height: 15px;
        background-image: url('../images/icons/check-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @media (min-width: 768px) {
          left: 0;
          top: 3px;
        }
      }
    }
  }

  &.list-style-dots {
    list-style: none;
    padding: 0;

    li {
      position: relative;
      padding-left: 0;

      &::before {
        position: absolute;
        content: "";
        top: 8px;
        left: -12px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: $text-main;
      }
    }
  }
}

img {
  border-style: none;
  //max-height: auto;
  max-width: 100%;
}

label {
  width: 100%;
}

.fw {
  &--100 {
    font-weight: 100 !important;
  }
  &--200 {
    font-weight: 200 !important;
  }
  &--300 {
    font-weight: 300 !important;
  }
  &--400 {
    font-weight: 400 !important;
  }
  &--500 {
    font-weight: 500 !important;
  }
  &--600 {
    font-weight: 600 !important;
  }
  &--700 {
    font-weight: 700 !important;
  }
  &--800 {
    font-weight: 800 !important;
  }
  &--900 {
    font-weight: 800 !important;
  }
}

.fz {
  &--16 {
    font-size: 16px !important;
  }
}

ul.list-style-custom {
  list-style: none;
  padding-left: 41px;

  li {
    position: relative;
    margin-bottom: 8px;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: -41px;
      width: 1.2em;
      height: 1.2em;
      background-image: url('../img/icons/check.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

.visually-hidden, /* - если убрать, то в IE11 лэйблы скачут */
.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--bs-gutter-x, $container-padding-x);
  padding-left: var(--bs-gutter-x, $container-padding-x);

  @media (min-width: 576px) {
    //max-width: 540px;
    max-width: 100%;
  }

  @media (min-width: 768px) {
    //max-width: 745px;
    max-width: 100%;
    padding-right: var(--bs-gutter-x, $container-md-padding-x);
    padding-left: var(--bs-gutter-x, $container-md-padding-x);
  }

  @media (min-width: 992px) {
    //max-width: 960px;
    max-width: 100%;
  }

  @media (min-width: 1200px) {
    //max-width: 1140px;
    max-width: 100%;
  }

  @media (min-width: 1280px) {
    max-width: 1280px;
    padding-right: var(--bs-gutter-x, $container-xxl-padding-x);
    padding-left: var(--bs-gutter-x, $container-xxl-padding-x);
  }
}

.wide-container {
  @media (min-width: 768px) {
    //max-width: 745px;
    max-width: 100%;
    padding-right: var(--bs-gutter-x, 0.65rem);
    padding-left: var(--bs-gutter-x, 0.65rem);
  }

  @media (min-width: 992px) {
    //max-width: 960px;
    max-width: 100%;
  }

  @media (min-width: 1200px) {
    //max-width: 1140px;
    max-width: 100%;
  }

  @media (min-width: 1280px) {
    max-width: 1280px;
    padding-right: var(--bs-gutter-x, 1.25rem);
    padding-left: var(--bs-gutter-x, 1.25rem);
  }
}

// CHECKBOX
.custom-checkbox-label {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  transition: 0.2s ease-in-out;
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  margin-right: 10px;
  margin-bottom: 0;
  border: 1px solid $checkbox-border;

  &:hover {
    background-color: $checkbox-bg;
  }

  &::before {
    position: absolute;
    top: 7px;
    left: 2px;
    width: 5px;
    height: 2px;
    background-color: #fff;
    transform: rotate(45deg);
  }

  &::after {
    position: absolute;
    top: 6px;
    left: 4px;
    width: 9px;
    height: 2px;
    background-color: #fff;
    transform: rotate(-45deg);
  }
}

.custom-checkbox {
  display: none;

  &:checked {
    + .custom-checkbox-label {
      background-color: $checkbox-bg;

      &::before {
        content: '';
      }

      &::after {
        content: '';
      }
    }
  }
}

// RADIO BUTTON
.custom-radio-label {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  transition: 0.2s ease-in-out;
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  margin-right: 10px;
  border-radius: 50%;
  margin-bottom: 0;
  border: 1px solid $radio-border;

  &:hover {
    &::before {
      content: '';
      background-color: #adadad;
    }
  }

  &::before {
    position: absolute;
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    border-radius: 50%;
    background-color: $radio-bg;
  }
}

.custom-radio {
  display: none;

  &:checked {
    + .custom-radio-label {

      &::before {
        content: '';
      }
    }
  }
}

// Mobile overlay
.mobile-menu-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 3;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  visibility: hidden;
  transition: $transition;

  &.overlay-show {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transition: $transition;
  }
}

input[type="text"]::-webkit-input-placeholder /* Chrome/Opera/Safari */ {
  color: $placeholder-text;
  font-weight: 300;

  @media (min-width: 768px) {

  }
}

// Bootstrap 5 modal FIX
.modal-backdrop {
  min-width: 320px;
  min-height: 100%;
}

body.modal-open, .modal {
  @media (max-width: 319px) {
    padding-right: 0 !important;
  }
}
// Bootstrap 5 modal FIX

.form-control {
  &:focus {
    box-shadow: none;
  }
}

textarea::-webkit-input-placeholder {
  color: $placeholder-text;
  font-weight: 300;
}

input[type="text"]::-moz-placeholder /* Firefox 19+ */ {
  opacity: 1;
}

textarea::-moz-placeholder {
  opacity: 1;
}

input[type="text"]:-moz-placeholder /* Firefox 18- */ {
  opacity: 1;
}

textarea:-moz-placeholder /* Firefox 18- */ {
  opacity: 1;
}

textarea::placeholder {
  opacity: 1;
}

.inline-icon {
  position: relative;
  margin-right: 4px;
  top: -1px;
}

.flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.--just-space {
  justify-content: space-between;
}

.--just-center {
  justify-content: center;
}

.--just-end {
  justify-content: flex-end;
}

.--align-str {
  align-items: stretch;
}

.--align-center {
  align-items: center;
}

.--align-end {
  align-items: flex-end;
}

.--dir-col {
  flex-direction: column;
}

.text-uppercase-cancel {
  text-transform: unset !important;
}

*.text-accent {
  color: $accent !important;
}

*.bg-accent {
  background-color: $accent !important;
}

.section-title {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.8px;
  line-height: 1.32;
  margin-bottom: 16px;
  position: relative;

  @media (min-width: 768px) {
    font-size: 30px;
    margin-bottom: 62px;
  }

  &--big {
    text-transform: uppercase;
    position: static;

    &::before {
      content: none;
    }

    @media (min-width: 576px) {
      font-size: 45px;
    }

    @media (min-width: 768px) {
      font-size: 52px;
    }

    img {
      @media (max-width: 575px) {
        max-height: 40px;
      }
    }
  }
}

.link-with-arrow {
  position: relative;
  padding-right: 1.1em;

  &::before, &::after {
    position: absolute;
    content: '';
    //width: 7px;
    width: 0.389em;
    //height: 1px;
    height: 0.056em;
    background-color: $text-main;
    right: 0;
    transition: $transition;
  }

  &.accent {
    &::before, &::after {
      background-color: $accent;
    }

    &:hover {
      &::before, &::after {
        background-color: $accent-2;
      }
    }
  }

  &::before {
    top: calc(50% - 0.056em);
    transform: rotate(45deg);
  }

  &::after {
    bottom: calc(50% - 0.23em);
    transform: rotate(-45deg);
  }

  &:hover {
    &::before {
      transform:  translateX(5px) rotate(45deg);
      transition: $transition;
    }

    &::after {
      transform:  translateX(5px) rotate(-45deg);
      transition: $transition;
    }
  }
}

.link-letter-space {
  letter-spacing: 0.3px;
}

.ff-additional {
  font-family: 'Akzidenz-Grotesk Pro Ext', sans-serif;
}

.section-subtitle {
  font-weight: 400;
  font-size: 16px;
}

select.form-select {
  background-color: $accent;
  color: #fff;
  border-color: $accent;
  border-radius: $border-radius;
  background-image: url('../images/icons/dropdown-icon-white.svg');
  background-repeat: no-repeat;
  background-position: top calc(50% - -1px) left calc(50% - -37px);
  background-size: 15px;
  padding: 19px 44px 19px 20px;
  text-align: center;
  font-weight: 600;
  line-height: 20px;

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
  }
  
  option {
    background-color: #fff;
    color: $text-main;
  }
}

.icon-arrow {
  position: relative;
  padding-right: 1em;

  &::before {
    position: absolute;
    content: '';
    top: calc(50% - 0.12em);
    right: 0;
    width: 0.55em;
    height: 0.55em;
    background-image: url('../images/icons/dropdown-icon-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    transition: $transition;

    @media (min-width: 768px) {
      top: calc(50% - 0.20em);
    }
  }

  &.show {
    &::before {
      transform: rotate(-180deg) translateX(-5px);
      top: calc(50% - 0.3em);
    }
  }
}

.section {
  padding: 40px 0;

  @media (min-width: 768px) {
    padding: 58px 0;
  }

  @media (min-width: 992px) {
    //padding: 30px 0;
  }

  @media (min-width: 1200px) {
    //padding: 40px 0;
  }
}

.mini-margin {
  margin: 15px 0;
}

.text-pseudo-underline {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $text-main;
    transition: $transition;
    pointer-events: none;
  }

  &:hover {
    &::before {
      transform: translateY(2px);
      transition: $transition;
      background-color: $accent-link-hover;
    }
  }
}

.unclickable {
  pointer-events: none;
}

.border-50 {
  border-radius: 50%;
}

input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: calc(50% - 8px);
  left: -15px;
  background-image: none;
  width: 15px;
  height: 15px;
}

.mobile-menu-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 3;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  visibility: hidden;
  transition: $transition;

  &.overlay-show {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transition: $transition;
  }
}

span.text-icon {
  position: relative;
  padding-right: 0.95em;

  &::before {
    position: absolute;
    content: "";
    bottom: 0.42em;
    right: 0.2rem;
    width: 0.47em;
    height: 0.47em;
    border: 0.1em solid $accent;
    transform: rotate(45deg);
    border-left-color: transparent;
    border-bottom-color: transparent;
    transition: $transition;
  }

  /*

  &::before, &::after {
    position: absolute;
    content: "";
    bottom: 0.6em;
    right: 0;
    width: 0.4em;
    height: 0.05em;
    background-color: $accent;
}

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    bottom: 0.35em;
    transform: rotate(-45deg);
  }

   */


  &--white {
    &::before {
      border-color: #fff;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &:hover {
    text-decoration: underline;

    &::before {
      transform: translateX(5px) rotate(45deg) ;
      transition: $transition;
    }
  }
}

.form-control {
  border-radius: 5px;
  background: #fff;
  border: none;
  padding: 18px 26px;
}

.btn, button, input[type="button"],
input[type="reset"], input[type="submit"] {
  max-width: 100%;
  font-weight: 600;
  font-size: 14px;
  //display: inline-flex;
  //align-items: center;
  //justify-content: center;
  color: $btn-txt !important;
  background-color: $btn-bg;
  cursor: pointer;
  text-decoration: none;
  //margin: 0;
  border-radius: $border-radius;
  overflow: hidden;
  border: 2px solid $btn-bg;
  padding: 15px 25px 15px;
  letter-spacing: 0.3px;


  &:hover {
    border-color: $btn-bg-hover;
    background-color: $btn-bg-hover;
    color: $btn-txt-hover !important;
  }

  &.btn--outline {
    background-color: transparent;
    color: $text-main !important;
    border-color: #000;
    font-weight: 800;

    &:hover {
      background-color: transparent;
      border-color: #222;
      color: #222 !important;
    }
  }

  &.btn--fill {
    background-color: $btn-bg;
    color: #fff;
    border-color: $btn-bg;

    &:hover {
      background-color: $btn-bg-hover;
      border-color: $btn-bg-hover;
    }
  }

  &.btn--dark {
    background-color: #000;
    border-color: #000;
    color: #fff;

    &:hover {
      border-color: #222;
      background-color: #222;
    }
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"], select, textarea {
  background: $input-bg;
  border-radius: $border-radius;
  //box-shadow: none;
  color: $text-input;
  height: auto;
  font-size: 16px;
  line-height: 19px;
  padding: 15px 30px 15px 18px;
  width: 100%;
  letter-spacing: 0.3px;

  &:focus {
    border-color: $accent;
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: $text-muted;
    //font-weight: 400;
    //font-size: 16px;
  }

  &::-moz-placeholder {
    color: $text-muted;
    //font-weight: 400;
    //font-size: 16px;
  }

  &:-ms-input-placeholder {
    color: $text-muted;
    //font-weight: 400;
    //font-size: 16px;
  }

  &:-moz-placeholder {
    color: $text-muted;
    //font-weight: 400;
    //font-size: 16px;
  }

  &:placeholder-shown {

  }

  &.primary-color-input {
    color: $text-primary;
  }
}

textarea {
  border-radius: $border-radius-min;
  border: none;
}

.form-control:focus {
  color: $text-input;
}

.form-element {
  &.form-control-search {
    position: relative;

    button {
      padding: 5px 5px;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 40px;
      background: transparent;
      border: none;
      box-shadow: none;

      svg {
        position: relative;
        top: calc(50% - 13px);
        left: 4px;
        transition: all .2s ease;

        path {
          transition: all .1s ease;
        }

        @media (min-width: 768px) {
          top: calc(50% - 19px);
          left: 2px;
        }
      }

      &:hover {
        svg, path {
          fill: #8a8a8a;
          transition: all .2s ease;
        }
      }
    }

    input {
      padding: 10px 20px 10px 45px;
      font-weight: 400;
      font-size: 12px;

      @media (min-width: 768px) {
        font-size: 14px;
        padding: 14px 20px 14px 45px;
      }

      &:focus {
        //outline: 1px solid #f5f5f5;
      }

      &::-webkit-input-placeholder {
        font-size: 12px;
        font-weight: 400;
        color: $text-muted;

        @media (min-width: 768px) {
          font-size: 14px;
        }
      }

      &::-moz-placeholder {
        font-size: 12px;
        font-weight: 400;
        color: $text-muted;

        @media (min-width: 768px) {
          font-size: 14px;
        }
      }

      &:-ms-input-placeholder {
        font-size: 12px;
        font-weight: 400;
        color: $text-muted;

        @media (min-width: 768px) {
          font-size: 14px;
        }
      }

      &:-moz-placeholder {
        font-size: 12px;
        font-weight: 400;
        color: $text-muted;

        @media (min-width: 768px) {
          font-size: 14px;
        }
      }

      &:placeholder-shown {
        font-size: 12px;
        font-weight: 400;
        color: $text-muted;

        @media (min-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
}


.element-with-icon {
  display: flex;
  flex-direction: row !important;

  i {
    align-self: center;
    margin-right: 14px;

    + * {
      align-self: center;
    }
  }
}


.accent .link-arrow-icon {
  display: inline-block;
  position: relative;
  padding-right: 13px;

  &::before {
    filter: $accent-filter;
    bottom: 2px;
  }
}

.link-arrow-icon {
  &::before {
    position: absolute;
    content: "";
    top: calc(50% - 2px);
    right: -15px;
    width: 8px;
    height: 8px;
    background-image: url('../images/icons/arrow-right.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: all .2s ease;
  }

  &:hover {
    &::before {
      transform: translateX(2px);
      transition: all .2s ease;
    }
  }
}

.main-text14 {
  font-size: 14px;
  line-height: 1.35;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  p {
    margin-bottom: 20px;

    @media (min-width: 768px) {
      margin-bottom: 18px;
    }
  }
}

.main-text16 {
  font-size: 16px;
  letter-spacing: 0.3px;

  p {
    margin-bottom: 19px;
  }
}

.yandex-map-container {
  width: 100%;
  height: 100%;
  background-color: #fff;

  #map {
    width: 100%;
    min-height: 300px;
    height: 100%;
  }
}

/*
.simplebar-content-wrapper {
  overflow-y: scroll !important;
}

.simplebar-track.simplebar-vertical {
  visibility: visible !important;
}

.simplebar-scrollbar.simplebar-visible {
  display: block !important;
}

 */


.selectBox {
  //border: 1px solid transparent;
  background-color: $accent;
  color: #fff;
  position: relative;
  padding: 20px 35px 20px;
  cursor: pointer;
  border-radius: $border-radius;
  transition: all .2s ease;
  text-align: center;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 22px solid $accent;
    z-index: 2;
    pointer-events: none;
    user-select: none;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    transition: all .2s ease;
  }

  &__value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-right: 25px;

    span {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 15px;
        height: 12px;
        right: -26px;
        top: calc(50% - -1px);
        transform: translateY(-50%) rotate(0deg);
        transition: all 0.2s ease-in-out;
        background-image: url('../images/icons/dropdown-icon-white.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

  }

  .dropdown-menu {
    transition: all .2s ease;
    opacity: 0;
    display: block;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 250px;
    z-index: -1;
    overflow-y: auto;
    transform: translateY(-15%);
    visibility: hidden;
  }

  &.show {
    //background-color: #fff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: all .2s ease;

    &::before {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      transition: all .2s ease;
    }

    .selectBox__value {
      span {
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    .dropdown-menu {
      transition: all .2s ease;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      // max-height: 300px;
      visibility: visible;

      opacity: 1;
      z-index: 1;

      transform: translateY(-1px);
    }
  }

  .dropdown-item.active, .dropdown-item:active {
    background-color: #2a2a2a;
  }
}

.simplebar-track.simplebar-vertical {
  width: 10px;
}

.simplebar-scrollbar.simplebar-visible::before {
  background-color: $accent;
  border-radius: 4px;
  width: 3px;
  opacity: 0.5 !important;
}

.simplebar-hover .simplebar-scrollbar::before {
  opacity: 1 !important;
}


