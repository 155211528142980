footer {
  background-color: $footer-bg;
  padding-top: 47px;
  margin-top: 31px;
  color: $footer-text;
  overflow: hidden;

  @media (min-width: 576px) {
    text-align: left;
  }

  @media (min-width: 768px) {
    padding-top: 67px;
    margin-top: 50px;
  }

  @media (min-width: 1200px) {
    padding-top: 67px;
  }

  .row {
    .footer__col {
      &:first-child {
        @media (min-width: 992px) {
          width: 15%;
        }

        @media (min-width: 1200px) {
          width: 12.6%;
        }

        & > .row {
          @media (min-width: 768px) {
            height: 100%;
          }
        }
      }

      &:last-child {
        @media (min-width: 992px) {
          width: 100%;
        }

        @media (min-width: 1200px) {
          width: 100%;
        }
      }
    }


    .col__logo {
      margin-bottom: 34px;

      @media (min-width: 768px) {
        margin-top: 7px;
        margin-bottom: 44px;
      }

      @media (min-width: 1280px) {
        margin-bottom: 0;
      }
    }

    .col__menu {
      margin-bottom: 33px;

      @media (min-width: 576px) {
        margin-bottom: 0;
      }

      @media (min-width: 768px) {
        margin-bottom: 35px;
      }

      @media (min-width: 1200px) {
        margin-bottom: 0;
      }
    }
    
    .col__contacts {

    }

    .col__icons {
      margin-top: 32px;
      margin-bottom: 40px;

      @media (min-width: 768px) {
        margin-bottom: 0;
        margin-top: 11px;
      }

      @media (min-width: 1200px) {
        margin-top: 0;
        position: relative;
      }
    }

    [class^="col"] {
      &:first-child {

      }

      &:nth-child(2) {

      }

      &:nth-child(3) {

      }

      &:nth-child(5) {

      }
    }
  }
}

.footer-logo {
  display: flex;
  flex-direction: column;


  &:hover {
    text-decoration: none;
  }

  .footer-logo__img {
    width: 100px;
    margin: 0 auto;

    @media (min-width: 768px) {
      margin: 0;
    }
  }

  .footer-logo__brand-text {
    span {
      &:first-child {

      }

      &:last-child {
        margin-left: 5px;
        img {
          position: relative;
          top: -1px;
          left: -4px;
        }
      }
    }
  }

  .footer-logo__additional {
    color: $footer-text;
    font-size: 9px;
    margin-top: 4px;
    display: inline-block;
    text-align: center;
    position: relative;
    left: -3px;
    letter-spacing: 0.2px;

    @media (min-width: 768px) {
      text-align: left;
      left: 0;
    }
  }
}

footer a {
  color: $footer-text;

  &:hover {
    text-decoration: underline;
    color: $footer-text;
  }
}

.footer-payments__wrap {
  @media (min-width: 1200px) {
    display: flex;
    height: 100%;
  }
}

.footer-payments {
  display: flex;
  flex-wrap: wrap;
  opacity: 0.65;

  @media (min-width: 576px) {
    justify-content: flex-start;
  }

  @media (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    margin-top: 20px;
  }

  @media (min-width: 1200px) {
    margin-top: auto;
    margin-left: auto;
    margin-bottom: 2px;
    margin-right: 0;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 17px;

      @media (min-width: 992px) {
        margin-right: 9px;
      }

      @media (min-width: 1200px) {
        margin-right: 16px;
      }
    }
  }
}

.footer-menu {
  ul {
    
    a {
      font-size: 14px;

      @media (min-width: 768px) {
        letter-spacing: 1px;

      }

      &:hover {
        color: $footer-text;
      }
    }
  }
}

.footer-menu__list--devices {
  @media (min-width: 576px) {
    //columns: 3;
    //-webkit-columns: 3;
    //-moz-columns: 3;
  }

  @media (min-width: 768px) {
    //columns: 1;
    //-webkit-columns: 1;
    //-moz-columns: 1;
  }
}

.footer-menu__title {
  margin-bottom: 19px;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  text-transform: uppercase;

  @media (min-width: 768px) {
    margin-bottom: 38px;
  }

}

.footer-menu__list {
  margin-bottom: 5px;

  li {

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    a {

    }
  }
}

.footer-menu__contacts {
  a {
    margin-top: 15px;
    display: block;
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #000;

    &:hover {
      color: $footer-text;
    }
  }
}

.footer-menu__additional {
  font-weight: 500;
  font-size: 12px;
  color: #A4A4A4;
  //opacity: 0.5;
}

.footer-menu__additional-time {
  margin-top: 8px;
  display: inline-block;
}

.footer-menu__email {
  margin-top: 26px !important;
}

.footer-menu__address {
  color: #000;
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-top: 24px;
  max-width: 300px;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: auto;

  @media (min-width: 768px) {
    margin-left: 0;
  }
}

.features + .footer {
  @media (min-width: 1200px) {
    margin-top: 80px;
  }
}

.footer__bottom-line {
  background-color: $footer-bg;
  padding: 17px 0 15px;
  border-top: 1px solid $border-line-color;

  @media (min-width: 768px) {
    margin-top: 69px;
    padding: 24px 0 24px;
  }

  @media (min-width: 992px) {
    margin-top: 30px;
  }

  @media (min-width: 1200px) {
    margin-top: 66px;
  }
}

.footer__logo {
  margin-bottom: 20px;
  position: relative;
  display: inline-block;

  @media (min-width: 992px) {
    //display: flex;
    //justify-content: flex-end;
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    position: absolute;
    right: 16px;
  }

  img {
    width: 150px;
    position: relative;
    top: -4px;
  }
}

.footer__logo-text {
  position: absolute;
  right: 4px;
  bottom: -11px;
  font-size: 13px;
  font-weight: 500;
  display: block;
  text-align: center;
  margin-top: 3px;
  color: #000;
  white-space: nowrap;
}

.footer-copyright {
  letter-spacing: 0.2px;

  @media (min-width: 768px) {
    display: flex;
    position: relative;

    & > * {
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  p {
    margin-bottom: 14px;
    font-size: 12px;
    font-weight: 400;
    color: #A4A4A4;

    @media (min-width: 768px) {
      font-size: 14px;
    }

    &:last-child {
      margin-bottom: 0;

      @media (min-width: 992px) {
        margin-left: auto;
      }
    }
  }

  a {
    display: block;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 14px;
    color: #A4A4A4;

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }

  .lang {
    @media (min-width: 768px) {
      position: absolute;
      right: 6px;
      top: -58px;
      font-size: 14px;
    }

    @media (min-width: 992px) {
      position: static;
    }
  }
}


body.base {
  .footer {
    margin-top: 71px;

    @media (min-width: 768px) {
      margin-top: 101px;
    }

    @media (min-width: 1200px) {
      margin-top: 70px;
    }
  }
}